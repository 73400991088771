/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.dexplore{
	height:250vh;
	background-color: #000222;
	width: 100%;
}
.searchlogoexplore{
	width:6vw;
	height:4.7vh;
	margin-top: 10vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	right: 20vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbarexplore{
	position: absolute;
	left: 17vw;
	width: 50vw;
	margin-left: 20px;
	min-width: 50vw;
	height: 6.2vh;
	margin-top: 10vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogoexplore:hover{
	border-right: 30px solid green;
}
.expsearch{
	background-color: #000222;
	width: 100%;
	height: 20vh;

}
.dexplorespots{
	width: 80vw;
	position: absolute;
	left: 10vw;
	height: 50vh;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
	/*padding: 10px;*/
	border-radius: 50px;
	object-fit: cover;
	overflow: hidden;
		/*border: 2px solid white;*/
}
.more{
	margin-top: 165vh;
	background-color: green;
	width: 100%;
}
.moret{
	position: absolute;
	right: 20vw;
	margin-top: 30px;
}
.spots{
	/*background-color: green;*/
	position: relative;
	height: 50vh;
	width: 80vw;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
}
.spotsoverlay{
	z-index: 2;
	height: 20vh;
	width: 86vw;
	position: absolute;
	top: 31.5vh;
	left: 7vw;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	/*margin: 20px;
	padding: 10px;*/
	opacity: 0.5;
	background-color : black;
	filter: blur(20px);
}
.dexploreimg{
	position: relative;
	height: 50vh;
	width: 80vw;
	display: grid;
	grid-gap: 5vh;
}
.spotsoverlaytext{
	position: absolute;
	top : 35vh;
	left: 10vw;
	text-align: center;
	font-size: 2rem;
	z-index: 3;
	width: 80vw;
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 1023px){
.dexplore{
	height:250vh;
	background-color: #000222;
	width: 100%;
}
.searchlogoexplore{
	width:6vw;
	height:4.3vh;
	margin-top: 10vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	right: 20vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbarexplore{
	position: absolute;
	left: 17vw;
	width: 50vw;
	margin-left: 20px;
	min-width: 50vw;
	height: 5.9vh;
	margin-top: 10vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogoexplore:hover{
	border-right: 30px solid green;
}
.expsearch{
	background-color: #000222;
	width: 100%;
	height: 20vh;

}
.dexplorespots{
	width: 80vw;
	position: absolute;
	left: 10vw;
	height: 50vh;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
	/*padding: 10px;*/
	border-radius: 50px;
	object-fit: cover;
	overflow: hidden;
		/*border: 2px solid white;*/
}
.more{
	margin-top: 165vh;
	background-color: green;
	width: 100%;
}
.moret{
	position: absolute;
	right: 20vw;
	margin-top: 30px;
}
.spots{
	/*background-color: green;*/
	position: relative;
	height: 50vh;
	width: 80vw;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
}
.spotsoverlay{
	z-index: 2;
	height: 20vh;
	width: 80vw;
	position: absolute;
	top: 31.5vh;
	left: 1.2vw;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	/*margin: 20px;
	padding: 10px;*/
	opacity: 0.5;
	background-color : black;
	filter: blur(20px);
}
.dexploreimg{
	position: relative;
	height: 50vh;
	width: 80vw;
	display: grid;
	grid-gap: 5vh;
}
.spotsoverlaytext{
	position: absolute;
	top : 35vh;
	left: 10vw;
	text-align: center;
	font-size: 2rem;
	z-index: 3;
	width: 80vw;
}
}
/*For Laptops:*/
@media(min-width: 1024px){
.dexplore{
	height:115vh;
	background-color: #000222;
	width: 100%;
}
.searchlogoexplore{
	width:3vw;
	height:5vh;
	margin-top: 20vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	right: 20vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbarexplore{
	position: absolute;
	left: 25vw;
	margin-left: 20px;
	min-width: 50vw;
	height: 6.3vh;
	margin-top: 20vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogoexplore:hover{
	border-right: 30px solid green;
}
.expsearch{
	/*background-color: green;*/
	width: 100%;
	height: 30vh;

}
.dexplorespots{
	width: 40vw;
	height: 50vh;
	margin: 20px;
	padding: 10px;
	border-radius: 50px;
	object-fit: cover;
	overflow: hidden;
		/*border: 2px solid white;*/
}
/*.imgblur{
	/*position: absolute;*/
/*	z-index: 5;
	width: 40vh;*/
	/*border-bottom-right-radius: 50px;
	border-bottom-left-radius: 50px;
	width: 40vh;
	height: 18vh;
	margin: 30px;
	padding: 10px;
	opacity: 0.6;
	filter: blur(10px);
	background-color: black;
	background-position: -200px ;
}*/
/*.imgblur0{
	margin-top: -26vh;
}*/*/
.more{
	margin-top: 5vh;
	background-color: green;
	width: 100%;
}
.moret{
	position: absolute;
	right: 20vw;
	margin-top: 30px;
}
.spots{
	/*background-color: green;*/
	position: relative;
	height: 50vh;
	width: 20vw;
	margin: 20px;
	display: inline-block;
}
.spotsoverlay{
	z-index: 2;
	height: 20vh;
	width: 20vw;
	position: absolute;
	top: 31.5vh;
	left: 1.2vw;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	margin: 20px;
	padding: 10px;
	opacity: 0.5;
	background-color : black;
	filter: blur(20px);
}
.dexploreimg{
	position: relative;
	height: 50vh;
	width: 100%;
	display: inline-block;
}
/*.spotsoverlay{
	position: absolute;
	top: 25vh;
	left: 4vh;
	z-index: 5;
	transform: rotate(-51.34deg);
	font-size: 3em;
	width: 40vh;
}*/
.spotsoverlaytext{
	position: absolute;
	top : 35vh;
	left: 2vw;
	font-size: 2rem;
	z-index: 3;
	width: 20vw;
}
}
