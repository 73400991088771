@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
/**/
/*For mobile device:*/
@media(min-width: 240px) and (max-width: 480px){
	.navbar1
{	
	background-color: #000222;
	display: none;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 50px 0px lightblue;
	overflow: hidden;
	z-index: 997;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}
.searchbarlogo{
	height: auto;
	width: auto;
	display: flex;
}
.logo1{
	position: absolute;
	left: 0px;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchbartext{

}
.searchbarimg{

}
/*.searchlogo{
	width:6vw;
	height:5vh;
	margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbar{
	position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	height: 6.3vh;
	margin-top: 4vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}*/
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
	.navbar1
{	
	background-color: #000222;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 50px 0px lightblue;
	overflow: hidden;
	z-index: 999;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}
.searchbarlogo{
	height: auto;
	width: auto;
}
.logo1{
	position: absolute;
	left: 0px;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchlogo{
	width:5vh;
	height:5vh;
	margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbar{
	position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	height: 6.3vh;
	margin-top: 4vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
}
}
/*For Laptops and small screen:*/
@media(min-width: 869px) and (max-width: 1023px){
.navbar1
{	
	background-color: #000222;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 30px 0px lightblue;
	overflow: hidden;
	z-index: 999;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}

.logo1{
	position: absolute;
	left: 0px;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchbarlogo{
	/*background-color: red;*/
	/*position: absolute;*/
	margin-left: 30vw;
	height: auto;
	width: 20vw;
	display: flex;
}
.searchbartext{
	/*position: absolute;*/
	/*top: 10px;
	left: 0px;*/
	/*background-color: green;*/
	height: 5vh;
	width: auto;
}
.searchbarimg{
	/*position: absolute;*/
	/*right: 0px;
	top: 0px;*/
	margin-top: 0px;
	/*background-color: blue;*/
	height: 5vh;
	width: auto;
	
}
.searchlogo{
	/*position: absolute;*/
	height:5vh;
	background-color: white;
	margin-top: 5vh;
	object-fit: fill;
	border-right: 2vw solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	/*margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;*/
}
.searchbar{
	/*height: 6.3vh;*/
	/*position: absolute;*/
	/*position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	margin-top: 4vh;*/
	height: 5vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
}
}

@media(min-width: 1024px){
.navbar1
{	
	background-color: #000222;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 30px 0px lightblue;
	overflow: hidden;
	z-index: 999;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}

.logo1{
	position: absolute;
	left: 0px;
	display: flex;
	margin-bottom: 2vh;
	/*background-color: gray;*/
	top: 0px;
	vertical-align: middle;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchbarlogo{
	/*background-color: red;*/
	/*position: absolute;*/
	margin-left: 30vw;
	height: auto;
	width: 20vw;
	display: flex;
}
.searchbartext{
	/*position: absolute;*/
	/*top: 10px;
	left: 0px;*/
	/*background-color: green;*/
	height: 5vh;
	width: auto;
}
.searchbarimg{
	/*position: absolute;*/
	/*right: 0px;
	top: 0px;*/
	margin-top: 0px;
	/*background-color: blue;*/
	height: 5vh;
	width: auto;
	
}
.searchlogo{
	/*position: absolute;*/
	height:5.1vh;
	background-color: white;
	margin-top: 4.9vh;
	object-fit: fill;
	border-right: 2vw solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	/*margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;*/
}
.searchbar{
	/*height: 6.3vh;*/
	/*position: absolute;*/
	/*position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	margin-top: 4vh;*/
	height: 5vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
	top: 0px;
	/*background-color: gray;*/
}
}