@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.dblog{
	height: 120vh;
	display: grid;
	grid-gap: 0px;
}
.left{
	height: 30vh;
	width: 100%;
	/*background-color: red;*/
	vertical-align: middle;
	justify-content: center;
}
.right{
	height: 90vh;
	width: 100%;
	/*background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	width: 100%;
	text-align: center;
	/*margin-top: 1vh;*/
}
.dptext5{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
	/*margin-top: -40px;*/
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 1rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	/*right: 25vw;*/
	height: 90vh;
	width: 100%;
	object-fit: fill;
	/*top: 40vh;*/
}
.dblog2{
	position: absolute;
	/*right: 20vw;*/
	height: 90vh;
	width: 100%;
	/*top: 20vh;*/
	object-fit: fill;
	
}
.dblog3{
	position: absolute;
	/*top: 0px;
	right: 15vw;*/
	height: 90vh;
	width: 100%;
	object-fit: cover;
	
}
.dblog3 img{
	position: absolute;
	height: 60vh;
	width: 50vw;
	left: 25vw;
}
.dblog2 img{
	height: 50vh;
	width: 40vw;
	position: absolute;
	left: 30vw;
	top: 20vh;
}
.dblog1 img{
	height: 40vh;
	width: 30vw;
	position: absolute;
	left: 35vw;
	top: 45vh;
}
.dblogimg{
	height: 80vh;
	width: 100%;
}

.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}

@media(min-width: 481px) and (max-width: 868px){
.dblog{
	height: 120vh;
	display: grid;
	grid-gap: 0px;
}
.left{
	height: 30vh;
	width: 100%;
	/*background-color: red;*/
	vertical-align: middle;
	justify-content: center;
}
.right{
	height: 90vh;
	width: 100%;
	/*background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	width: 100%;
	text-align: center;
	/*margin-top: 1vh;*/
}
.dptext5{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
	/*margin-top: -40px;*/
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 1rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	/*right: 25vw;*/
	height: 90vh;
	width: 100%;
	object-fit: fill;
	/*top: 40vh;*/
}
.dblog2{
	position: absolute;
	/*right: 20vw;*/
	height: 90vh;
	width: 100%;
	/*top: 20vh;*/
	object-fit: fill;
	
}
.dblog3{
	position: absolute;
	/*top: 0px;
	right: 15vw;*/
	height: 90vh;
	width: 100%;
	object-fit: cover;
	
}
.dblog3 img{
	position: absolute;
	height: 60vh;
	width: 50vw;
	left: 25vw;
}
.dblog2 img{
	height: 50vh;
	width: 40vw;
	position: absolute;
	left: 30vw;
	top: 20vh;
}
.dblog1 img{
	height: 40vh;
	width: 30vw;
	position: absolute;
	left: 35vw;
	top: 45vh;
}
.dblogimg{
	height: 80vh;
	width: 100%;
}

.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}



/*For Tablets and iPads:*/
@media(min-width: 869px) and (max-width: 1024px){
.dblog{
	height: 100vh;
}
.left{
	height: 100vh;
	width: 50%;
	position: absolute;
	left: 0px;
	/*background-color: red;*/

}
.right{
	height: 100vh;
	width: 50%;
	position: absolute;
	right: 0px;
	/*background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	margin-left: 2vw;
	margin-top: 15vh;
}
.dptext5{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
	margin-top: -40px;
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 2rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	right: 26vw;
	height: 30vh;
	width: 20vw;
	object-fit: fill;
	top: 30vh;
	

}
.dblog2{
	position: absolute;
	right: 12vw;
	top: 28vh;
	height: 40vh;
	width: 25vw;
	object-fit: fill;
	
}
.dblog3{
	position: absolute;
	right: 2vw;
	top: 25vh;
	height: 50vh;
	width: 30vw;
	object-fit: fill;
	
}
.dblogimg{
	height: 100vh;
	width: 100%;
}
/*.odb1{
	z-index: 5;
	position: absolute;
	background-color: black;
	opacity: 0.1;
	right: 0vw;
	height: 38vh;
	width: 25vw;
	object-fit: cover;
	margin-top: -38vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb2{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vw;
	height: 47vh;
	width:35vw;
	object-fit: fill;
	margin-top: -47vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb3{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vw;
	height: 56.6vh;
	width:45vw;
	object-fit: fill;
	margin-top: -56.6vh;
	box-shadow: 5px 5px 5px 5px black;
}*/
.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}



/*For Laptops:*/
@media(min-width: 1025px){
.dblog{
	height: 120vh;
}
.left{
	height: 100vh;
	width: 50%;
	position: absolute;
	left: 0px;
/*	background-color: red;*/

}
.right{
	height: 100vh;
	width: 50%;
	position: absolute;
	right: 0px;
/*	background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	margin-left: 10vh;
	margin-top: 30vh;
}
.dptext5{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
	margin-top: -40px;
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 2rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	right: 50vh;
	height: 40vh;
	width: 25vh;
	object-fit: fill;
	top: 8vh;
	

}
.dblog2{
	position: absolute;
	right: 25vh;
	height: 40vh;
	width: 35vh;
	object-fit: fill;
	top: 4vh;
	
}
.dblog3{
	position: absolute;
	right: 4vh;
	height: 60vh;
	width: 45vh;
	object-fit: fill;
	
}
.dblogimg{
	margin-top: 25vh;
	margin-right: 25vh;
}
.odb1{
	z-index: 5;
	position: absolute;
	background-color: black;
	opacity: 0.1;
	right: 0vh;
	height: 38vh;
	width: 25vh;
	object-fit: cover;
	margin-top: -38vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb2{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vh;
	height: 47vh;
	width:35vh;
	object-fit: fill;
	margin-top: -47vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb3{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vh;
	height: 56.6vh;
	width:45vh;
	object-fit: fill;
	margin-top: -56.6vh;
	box-shadow: 5px 5px 5px 5px black;
}
.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}
