
/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.register_page{
  height: 100vh;
  width: 100vw;
}
.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:18vw;
 }
 .vaguss_text2{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.register_page{
  height: 100vh;
  width: 100vw;
}
.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:18vw;
 }
 .vaguss_text2{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}

/*For Laptops:*/
@media(min-width: 869px) and (max-width: 1024px){
.register_page{
  width: 100vw;
  height: auto;
}

.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  background: #f6f4f4;
  height: auto;
  width: auto;
  right:10%;
  float: right;
  display: inline;
  height: auto;
  width: auto;
  z-index: 3;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
 }
 .vaguss_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}


@media(min-width: 1025px){
.register_page{
  width: 100vw;
  height: auto;
}

.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  background: #f6f4f4;
  height: auto;
  width: auto;
  right:10%;
  float: right;
  display: inline;
  height: auto;
  width: auto;
  z-index: 3;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
 }
 .vaguss_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}