@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
/*For mobile device :*/
body{
	min-height: 100vh;
}
@media(min-width: 240px) and (max-width: 480px){
/*body{
	min-height: 100vh;
}*/
.write{
	min-height: 80vh;
	position: relative;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
}
.writebac{
	min-height: 70vh;
	display: grid;
	margin: 6%;
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(auto-fill , minmax(240px , 480px));
	/*display: grid;
	grid-template-columns: 1fr;*/
}
/*.writeus{
	min-height: 0.2fr;
	font-family: Montserrat;
	width: 80%;
	background-color: #000222;
	min-height: 10vh;
}*/
.writeus1{
	font-family: Great Vibes;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	font-weight: bold;
	font-size: 2rem;
	padding: 2vh;
	min-height: 10vh;
	vertical-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	background-color: #000222;
}
.zone{
	min-height: 50vh;
	font-family: Montserrat;
	color: black;
	/*font-weight: bold;*/
	font-size: 0.8rem;
	display: grid;
	grid-gap: 0px;
	grid-template-columns: repeat(auto-fill , , minmax(240px , 480px));
}
.writeleft{
	background-color: #F5F5F5;
	min-height: 30vh;
	/*min-height: 0.3fr;*/
}
.dataproblem{
	min-height: 25vh;
	margin-top: 2.5vh;
	font-size: 0.8rem;
	display: grid;
}
.writeright{
	background-color: green;
	min-height: 20vh;
	/*min-height: 0.3fr;*/
}
.mailbox{
	display: none;
}
.mailbox1{
	background-color: #F5F5F5;
	min-height: 6vh;
}
.mailbox1 label{
	font-size: 0.8rem;
}
.prblm{
	background-color: #F5F5F5;
}
.problem{
	min-height: 10vh;
	width: 80%;
	margin: 2vh;
	outline: none;
	border: none;
}
.submitbtn{
	background-color: #000222;
	/*width : 80%;*/
	min-height: 10vh;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	/*min-height: 0.2fr;*/
}
.submitbutton{
	min-height: 6vh;
	width: 32%;
	border-radius: 50px;
	margin-top: 2vh;
}
}
/*For Tablets:*/
@media(min-width: 481px) and (max-width: 1023px){
	.write{
	min-height: 80vh;
	position: relative;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
}
.writebac{
	min-height: 70vh;
	display: grid;
	margin: 6%;
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(auto-fill , minmax(240px , 480px));
	/*display: grid;
	grid-template-columns: 1fr;*/
}
/*.writeus{
	min-height: 0.2fr;
	font-family: Montserrat;
	width: 80%;
	background-color: #000222;
	min-height: 10vh;
}*/
.writeus1{
	font-family: Montserrat;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	font-weight: bold;
	font-size: 2rem;
	padding: 2vh;
	min-height: 10vh;
	vertical-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	background-color: #000222;
}
.zone{
	min-height: 50vh;
	display: grid;
	grid-gap: 0px;
	grid-template-columns: repeat(auto-fill , , minmax(240px , 480px));
}
.writeleft{
	background-color: #F5F5F5;
	min-height: 30vh;
	/*min-height: 0.3fr;*/
}
.dataproblem{
	min-height: 25vh;
	margin-top: 2.5vh;
	font-size: 1rem;
	display: grid;
}
.writeright{
	background-color: green;
	min-height: 20vh;
	/*min-height: 0.3fr;*/
}
.mailbox{
	display: none;
}
.mailbox1{
	background-color: #F5F5F5;
	min-height: 6vh;
}
.mailbox1 label{
	font-size: 1.2rem;
}
.prblm{
	background-color: #F5F5F5;
}
.problem{
	min-height: 10vh;
	width: 80%;
	margin: 2vh;
	outline: none;
	border: none;
}
.submitbtn{
	background-color: #000222;
	/*width : 80%;*/
	min-height: 10vh;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	/*min-height: 0.2fr;*/
}
.submitbutton{
	min-height: 6vh;
	width: 32%;
	border-radius: 50px;
	margin-top: 2vh;
}
}
/*For Laptops:*/
@media(min-width: 1024px){
.write{
	min-height: 100vh;
	/*margin-top: 5vh;*/
	margin-bottom: 5vh;
	width: 100%;
	background-color: white;
	position: relative;
}
.writebac{
	min-height: 80vh;
	width: 80vw;
	background-color: white;
	position: absolute;
	left: 10vw;
	margin-top: 10vh;
}
.writeus1{
	background-color: #000222;
	min-height: 10vh;
	font-family: Montserratt;
	font-size: 1.5rem;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	font-weight: bold;
	font-size: 2rem;
	padding: 2vh;
	vertical-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
}
.zone{
	min-height: 60vh;
}
.writeleft{
	width: 50%;
	position: absolute;
	left: 0px;
	background-color: #F5F5F5;
	min-height: 60vh;
}
.dataproblem{
	min-height: 35vh;
	margin-top: 5vh;
	font-size: 1rem;
	display: grid;
}
.writeright{
	width: 50%;
	position: absolute;
	right: 0px;
	background-color: #F5F5F5;
	min-height: 60vh;
}
.submitbtn{
	background-color: #000222;
	width : 100%;
	min-height: 10vh;
}
.prblm{
}
.problem{
	min-height: 30vh;
	width: 25vw;
	margin: 15vh;
	outline: none;
	border: none;
}
.mailbox{
	background-color: #F5F5F5;
	min-height: 6vh;
	margin: 3vh;
}
.mailbox label{
	font-size: 1.2rem;
}
.mailbox1{
	display: none;
}
.submitbutton{
	min-height: 6vh;
	width: 10vw;
	border-radius: 50px;
	margin-top: 2vh;
	font-size: 1.2rem;
}
}
/*Especially For Jio phone:
/*@media(max-width: 239px){

}*/