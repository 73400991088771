@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
/*For Mobile Phones:*/
@media(min-width: 40px){
	.DashboardNavbarSection{
		display: none;
	}
}
@media(min-width: 240px) and (max-width: 480px){
.dp{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_bigscreen{
	display: none;
}
.dptext{
	/*margin-left: 10vh;*/
	/*margin-top: 30vh;*/
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
	/*left: 10vw;*/
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.started{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	margin: 10px;
	margin-top: 3vh;
	position: absolute;
	left: 0px;
}
.overlay0{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.dp{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_bigscreen{
	display: none;
}
.dptext{
	/*margin-left: 10vh;*/
	/*margin-top: 30vh;*/
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
	/*left: 10vw;*/
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.started1btnold{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	margin: 10px;
	margin-top: 3vh;
	position: absolute;
	left: 0px;
}
.overlay0{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}


@media(min-width: 869px) and (max-width: 1024px){
.dp{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_smallscreen{
	display: none;
}
.dptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.started1btnold{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
.overlay0{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}


/*For Laptops:*/
@media(min-width: 1025px){
.dp{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_smallscreen{
	display: none;
}
.dptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.started1btnold{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
	outline: none;
	border: 5px solid white;
}
.overlay0{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}