

/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.SavedBlogpage{
    width: 100vw;
    height: auto;
}
.BlogSavedCard{
    width: 100vw;
    height: 30vh;
    /*padding: 6rem 1rem;*/
    overflow-y: scroll;
    display: grid;
    grid-gap: 2vh;
    /*grid-template-columns: 1fr 1fr;*/
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 96vw;
    height: 35vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 90vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    /*position: absolute;*/
    /*background: green;*/
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.BlogSavedtext{
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.linksaved{
    /*background: gray;*/
    /*position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;*/
    /*background: black;*/
    margin-top: 2vh;
    height: 3vh;
    width: 40vw;
}
.options-menu3{
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 6vh;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 5vh;
    width: 20vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}


/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.SavedBlogpage{
    width: 100vw;
    height: auto;
}
.BlogSavedCard{
    width: 100vw;
    height: auto;
    /*margin-left: 5vw;*/
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 2vh;
    /*grid-template-columns: 1fr 1fr;*/
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: 35vh;
    overflow-y: scroll;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 96vw;
    height: auto;
    margin-left: 2vw;
    margin-top: 2vh;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 96vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    /*position: absolute;*/
    /*background: green;*/
    height: auto;
    width: 85vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linksaved{
    /*background: gray;*/
    /*position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;*/
    /*background: black;*/
    height: 3vh;
    width: 50vw;
}
.options-menu3{
    position: absolute;
    top: 12vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.SavedBlogpage{
    width: 100vw;
    height: auto;
}
.BlogSavedCard{
    width: 45vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 4vh;
    grid-template-columns: 1fr 1fr;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 28vw;
    height: 37vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 28vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    position: absolute;
    /*background: green;*/
    height: 17vh;
    width: 26vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linksaved{
    /*background: gray;*/
    /*position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;*/
    /*background: black;*/
    margin-top: 1vh;
    height: 3vh;
    width: 50vw;
}
.options-menu3{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For large screens:*/
@media(min-width: 1024px){
.BlogSavedCard{
    width: 45vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 4vh;
    grid-template-columns: 1fr 1fr;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 28vw;
    height: 30vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 28vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    position: absolute;
    /*background: green;*/
    height: 17vh;
    width: 26vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linksaved{
    /*background: gray;*/
    position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;
    /*background: black;*/
    height: 3vh;
    width: 10vw;
}
.options-menu3{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}