@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.Form
{
	/*background-color: #f6f4f4;
	min-height: 60vh;
	min-width: 400px;
	right:10%;
	margin-top: 15vh;
	float: right;
	display: inline;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.register_container{
	background-color: #f6f4f4;
	height: auto;
	width: 80vw;
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.already_accnt{
	text-align: center;
	font-size: 0.7rem;
	width: 100%;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.Form
{
	/*background-color: #f6f4f4;
	min-height: 60vh;
	min-width: 400px;
	right:10%;
	margin-top: 15vh;
	float: right;
	display: inline;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.register_container{
	background-color: #f6f4f4;
	height: auto;
	width: 80vw;
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.already_accnt{
	text-align: center;
	font-size: 0.7rem;
	width: 100%;
}
}

/*For Laptops:
*/
@media(min-width: 869px) and (max-width: 1024px){
.register_container
{
	background-color: #f6f4f4;
	position: absolute;
	right: 0px;
	margin-left: 0px;
	margin-right: 10%;
	margin-top: 8%;
	height: auto;
	width: auto;
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	right: 10%;
	
}
}

@media(min-width: 1025px){
.register_container
{
	background-color: #f6f4f4;
	position: absolute;
	right: 0px;
	margin-left: 0px;
	margin-right: 10%;
	margin-top: 8%;
	height: auto;
	width: auto;
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	right: 10%;
	
}	
}