


/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_largescreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	background-size: cover;
	object-fit: cover;
	top: 0vh;
	width: 55vw;
	height: 100vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	/*position: absolute;*/
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
	/*margin-left: 10vh;
	margin-top: 30vh;*/
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin:10px;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.loggedinhomestarted{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	margin: 10px;
	margin-top: 3vh;
	position: absolute;
	left: 0px;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_largescreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	background-size: cover;
	object-fit: cover;
	top: 0vh;
	width: 55vw;
	height: 100vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	/*position: absolute;*/
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.loggedinhomestarted{
	border-radius: 50px;
	width: 15vh;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_smallscreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	top: 10vh;
	width: 55vw;
	height: 90vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	position: absolute;
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.loggedinhomestarted{
	border-radius: 50px;
	width: 15vh;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
}

/*For large screens:*/
@media(min-width: 1024px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_smallscreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	top: 10vh;
	width: 55vw;
	height: 90vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	position: absolute;
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.loggedinhomestarted{
	border-radius: 50px;
	width: 15vh;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
}