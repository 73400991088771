/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.ShareBlogpage{
    width: 100vw;
    height: auto;
}
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    left: 0px;
    top: 0px;
    height: auto;
    width: 100%;
    /*margin-top: 5vh;
    margin-right: 5vw;*/
}

.card {
    position: absolute;
    left: 2vw;
    width: 96vw;
    height: 33vh;
    /*background-color: green;*/
   
}

/*.card:hover {
    background-color: #141414;
}*/
.sharebox{
    /*position: absolute;
    top: 0px;*/
    background-color: white;
    text-align: top-left;
    width: 96vw;
    height: 25vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 2vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 96vw;
    margin: 0px;
    margin-top: -0.4vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 5vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 25%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 6vh;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 5vh;
    width: 20vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}


/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.ShareBlogpage{
    width: 100vw;
    height: auto;
}
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.sharebox{
    background-color: white;
    text-align: top-left;
    width: 90vw;
    height: 25vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 5vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card {
    position: absolute;
    /*background-color: green;*/
    left: 5vw;
    width: 90vw;
    height: 35vh;
   
}

/*.card:hover {
    background-color: #141414;
}*/
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 90vw;
    margin: 0px;
    margin-top: -0.5vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 15%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 12vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}


/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.ShareBlogpage{
    width: 100vw;
    height: auto;
}
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.sharebox{
    background-color: white;
    text-align: top-left;
    width: 50vw;
    height: 35vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 5vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card {
    position: absolute;
    left: 5vw;
    width: 50vw;
    height: 35vh;
   
}

/*.card:hover {
    background-color: #141414;
}*/
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 50vw;
    margin: 0px;
    margin-top: -0.4vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 15%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}


/*For Large screens:*/
@media(min-width: 1024px){
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.sharebox{
    background-color: white;
    text-align: top-left;
    width: 50vw;
    height: 35vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 5vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card {
    position: absolute;
    left: 5vw;
    width: 50vw;
    height: 35vh;
   
}

/*.card:hover {
    background-color: #141414;
}*/
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 50vw;
    margin: 0px;
    margin-top: -0.3vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 15%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}




