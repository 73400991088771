@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap');
/*For mobile phones:*/
@media(min-width: 240px) and (max-width: 480px){
.dal{
	height: auto;
	width: 100%;
	display: grid;
	grid-gap: 0px;
}
.lefta{
	height: auto;
	width: 100vw;
	/*position: absolute;
	left: 0px;*/
	background-color: white;
	border-top: 5px solid #000222;
}
.righta{
	height: auto;
	width: 100vw;
	color: black;
	/*position: absolute;
	right: 0px;*/
	background-color: #000222;
	/*border-top: 5px solid white;*/
	display: grid;
	align-items: center;
	justify-content: space-around;
}
.aboutussummary{
	display: inline;
	width: 100%;
	font-family: Montserrat;
	/*font-size: 0.5em;*/
}
.aboutp{
	width: 85vw;
	margin: 7.5vw;
	/*margin-left: 5vw;*/
	font-family: Montserrat;
	font-size: 1rem;
	text-align: justify;
	color: black;
}
.founderpicimg1{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg2{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg3{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic2{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
	margin-bottom: 5vh;
}
.namefoundertext1{
	font-family: Montserrat;
	color: black;
}
.foundertext1{
	font-family: Montserrat;
	color: black;
}
.namefoundertext2{
	font-family: Montserrat;
	color: black;
}
.foundertext2{
	font-family: Montserrat;
	color: black;
}
.namefoundertext3{
	font-family: Montserrat;
	color: black;
}
.foundertext3{
	font-family: Montserrat;
	color: black;
}
.read-or-hide{
	color: blue;
	cursor: pointer;
	font-family: cursive;
	font-size: 0.5rem;
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 1023px){
.dal{
	height: auto;
	width: 100%;
	display: grid;
	grid-gap: 0px;
}
.lefta{
	height: auto;
	width: 100vw;
	/*position: absolute;
	left: 0px;*/
	background-color: white;
	border-top: 5px solid #000222;
}
.righta{
	height: auto;
	width: 100vw;
	color: black;
	/*position: absolute;
	right: 0px;*/
	background-color: #000222;
	/*border-top: 5px solid white;*/
	display: grid;
	align-items: center;
	justify-content: space-around;
}
.aboutussummary{
	display: inline;
	width: 100%;
	font-family: Montserrat;
	/*font-size: 0.5em;*/
}
.aboutp{
	width: 85vw;
	margin: 7.5vw;
	/*margin-left: 5vw;*/
	font-family: Montserrat;
	font-size: 1.0rem;
	text-align: justify;
	color: black;
}
.founderpicimg1{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg2{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg3{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic2{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
	margin-bottom: 5vh;
}
.namefoundertext1{
	font-family: Montserrat;
	color: black;
}
.foundertext1{
	font-family: Montserrat;
	color: black;
}
.namefoundertext2{
	font-family: Montserrat;
	color: black;
}
.foundertext2{
	font-family: Montserrat;
	color: black;
}
.namefoundertext3{
	font-family: Montserrat;
	color: black;
}
.foundertext3{
	font-family: Montserrat;
	color: black;
}
.read-or-hide{
	color: blue;
	cursor: pointer;
	font-family: cursive;
	font-size: 0.5em;
}
}
/*For Laptops:*/
@media(min-width: 1024px){
.dal{
	height: 110vh;
	width: 100%;
}
.lefta{
	height: 110vh;
	width: 50vw;
	position: absolute;
	left: 0px;
	background-color: white;
	border-top: 5px solid #000222;
}
.aboutussummary{
	display: inline;
	width: 100%;
	font-family: Montserrat;
	/*font-size: 0.5em;*/
}
.righta{
	height: 110vh;
	width: 50vw;
	position: absolute;
	right: 0px;
	color: white;
	background-color: #000222;
	/*border-top: 5px solid white;*/
}
.aboutp{
	width: 40vw;
	margin-left: 5vw;
	font-size: 1.3rem;
	text-align: justify;
	color: black;
}
.founderpicimg1{
	height: 30vh;
	width: 22vw;
	position: absolute;
	left: 0vw;
	object-fit: fill;
}
.founderpicimg2{
	height: 30vh;
	width: 22vw;
	position: absolute;
	right: 0vw;
	object-fit: fill;
}
.founderpicimg3{
	height: 30vh;
	width: 22vw;
	position: absolute;
	left: 0vw;
	object-fit: fill;
}
.founderpic1{
	position: absolute;
	margin: 3vw;
	height: 30vh;
	width: 44vw;
	top: 0vh;
	left: 0vw;
}
.cofounderpic1{
	position: absolute;
	top: 30vh;
	height: 30vh;
	width: 44vw;
	right: 0vw;
	margin: 3vw;
}
.cofounderpic2{
	position: absolute;
	top: 60vh;
	height: 30vh;
	width: 44vw;
	left: 0vw;
	margin: 3vw;
}
.namefoundertext1{
	position: absolute;
	top: 5vh;
	right: 8vw;
}
.foundertext1{
	position: absolute;
	top: 10vh;
	right: 7.5vw;
}
.namefoundertext2{
	position: absolute;
	top: 5vh;
	left: 9vw;
}
.foundertext2{
	position: absolute;
	top: 10vh;
	left: 9vw;
}
.namefoundertext3{
	position: absolute;
	top: 8vh;
	right: 8vw;
}
.foundertext3{
	position: absolute;
	top: 13vh;
	right: 7.5vw;
}
.read-or-hide{
	color: blue;
	cursor: pointer;
	font-family: cursive;
	font-size: 0.5rem;
}
}
