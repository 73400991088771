/*For Laptops:*/
@media(min-width: 1024px){
.footer{
	height: 10vh;
	width: 100%;
	background-color: #000222;
	box-shadow: 50px 50px 50px 50px #808080;
	margin-top: 10vh;
	z-index: 10;
	position: relative;
	bottom: 0px;
	border: none;
}
.sent{
	/*position: absolute;*/
	/*margin-top: 4vh;
	margin-left: 60vh;*/
	height: 10vh;
	width: 100%;
	justify-content: center;
	text-align: center;
	margin: 0px;
	height: 10vh;
	vertical-align: center;
}
.sent p{
	text-align: center;
	justify-content: center;
	padding: 2vh;
	font-size: 0.9rem;
	margin: 2vh;
}
}
/*For mobile device:*/
@media(min-width: 240px) and (max-width: 480px){ 
	.sent{
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer{
		height: 15vh;
		width: 100%;
		background-color: #000222;
		box-shadow: 50px 50px 50px 50px #808080;
	/*	margin-top: 50px;
		margin-bottom: 0px;*/
		/*z-index: 10;*/
		position: relative;
		bottom: 0px;
	}
	.sent p{
		text-align: center;
		justify-content: center;
		padding: 3vh;
		font-size: 0.7rem;
		margin: 2vh;
	}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 1023px){
	.sent{
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer{
		height: 15vh;
		width: 100%;
		background-color: #000222;
		box-shadow: 50px 50px 50px 50px #808080;
		margin-top: 50px;
		z-index: 10;
		position: relative;
		bottom: 0px;
		margin-bottom: 0px;
	}
	.sent p{
		text-align: center;
		justify-content: center;
		padding: 3vh;
		font-size: 1rem;
		margin: 2vh;
	}
}
/*Especially For jio phone:*/
@media(max-width: 239px){
	.sent{
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer{
		height: 15vh;
		width: 100%;
		background-color: #000222;
		box-shadow: 50px 50px 50px 50px #808080;
		margin-top: 50px;
		margin-bottom: 0px;
		z-index: 10;
		position: relative;
		bottom: 0px;
	}
	.sent p{
		text-align: center;
		justify-content: center;
		padding: 3vh;
		font-size: 0.5rem;
		margin: 2vh;
	}
}