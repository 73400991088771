@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
/*
For Mobile Devices:*/
@media(min-width: 240px) and (max-width: 480px){
/*body{
	width: 100%;
}*/
.Form
{
	/*position: fixed;
	margin: 0px;
	height: 80vh;
	width: 80%;*/
	/*right:10%;
	top:-8vh;*/
	/*bottom: 50px;*/
	/*float: right;*/
	font-family: Montserrat;
	box-shadow: 0px 0px 0px 0px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 80vw;
	/*margin-top: 5vh;*/
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
	font-size: 1.5rem;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 60vw;
	height: auto;
	padding: 8px;
	font-family: Montserrat;

}
.Glogo{
	width: 4vw;
	margin-top:0px; 
	margin-right:2vw;
}
.Facebook{
	background-color: #0d66d0;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 8vw;
	margin-right: 2vw;

}
.flogotext{
	/*bottom: 0px;*/
	font-size: 0.7rem;
}
.Twitter{
	background-color: #1DA1F2;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 8vw;
	margin-right: 2vw;
}
.logotext{
	margin-bottom: 0px;
	bottom: 0px;
	font-size: 0.7rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.Form
{
	/*position: fixed;
	margin: 0px;
	height: 80vh;
	width: 80%;*/
	/*right:10%;
	top:-8vh;*/
	/*bottom: 50px;*/
	/*float: right;*/
	font-family: Montserrat;
	box-shadow: 0px 0px 0px 0px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 80vw;
	/*margin-top: 5vh;*/
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
	font-size: 1.5rem;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 60vw;
	height: auto;
	padding: 8px;
	font-family: Montserrat;

}
.Glogo{
	width: 4vw;
	margin-top:0px; 
	margin-right:2vw;
}
.Facebook{
	background-color: #0d66d0;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 8vw;
	margin-right: 2vw;

}
.flogotext{
	/*bottom: 0px;*/
	font-size: 0.7rem;
}
.Twitter{
	background-color: #1DA1F2;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 8vw;
	margin-right: 2vw;
}
.logotext{
	margin-bottom: 0px;
	bottom: 0px;
	font-size: 0.7rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}
}


@media(min-width: 869px) and (max-width: 1023px){
.Form
{
	/*min-height: 60vh;
	min-width: 400px;
	right:10%;
	top:-8vh;
	bottom: 50px;
	float: right;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 40vw;
}
label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 28vw;
	height: auto;
	font-family: Montserrat;
	padding: 10px;

}
.Glogo{
	width: 20px;
	margin-top:0px; 
	margin-right:8px;
}
.Facebook{
	background-color: #0d66d0;
	width: 28vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 40px;
	margin-right: 8px;
}
.flogotext{
	/*bottom: 13px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.Twitter{
	background-color: #1DA1F2;
	width: 28vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 40px;
	margin-right: 8px;
}
.logotext{
	/*margin-bottom: 50px;
	bottom: 4px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}
}

/*For Laptops:*/
@media(min-width: 1024px){
.Form
{
	/*min-height: 60vh;
	min-width: 400px;
	right:10%;
	top:-8vh;
	bottom: 50px;
	float: right;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 35vw;
}
label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 25vw;
	height: auto;
	font-family: Montserrat;
	padding: 10px;

}
.Glogo{
	width: 20px;
	margin-top:0px; 
	margin-right:8px;
}
.Facebook{
	background-color: #0d66d0;
	width: 25vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 40px;
	margin-right: 8px;
}
.flogotext{
	/*bottom: 13px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.Twitter{
	background-color: #1DA1F2;
	width: 25vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 40px;
	margin-right: 8px;
}
.logotext{
	/*margin-bottom: 50px;
	bottom: 4px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}	
}

