@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');


/*For Mobile Devices:*/
@media(min-width: 240px) and (max-width: 480px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.form_signin{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
  box-shadow: 2px 2px 2px 2px #2C2828;
  transition:all 0.08s linear;
  border-radius: 20px;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3vw;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:16vw;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.form_signin{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
  box-shadow: 2px 2px 2px 2px #2C2828;
  transition:all 0.08s linear;
  border-radius: 20px;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3vw;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:16vw;
}
}



@media(min-width: 869px) and (max-width: 1023px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
}
.form_signin{
  background: transparent;
  position: absolute;
  top: 10vh;
  height: auto;
  width: 50vw;
  right:5%;
  float: right;
  display: inline;
  box-shadow: 2px 2px 2px 2px #2C2828;
  border-radius: 10px;
  height: auto;
  width: auto;
  z-index: 3;
}
}

/*For Laptops:*/
@media(min-width: 1024px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
}
.form_signin{
  background: transparent;
  position: absolute;
  top: 10vh;
  height: auto;
  width: auto;
  right:10%;
  float: right;
  padding: 2vh;
  display: inline;
  box-shadow: 2px 2px 2px 2px #2C2828;
  border-radius: 10px;
  height: auto;
  width: auto;
  z-index: 3;
}
}