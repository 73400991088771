@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/**/
/*For mobile device:*/
@media(min-width: 240px) and (max-width: 480px){
	.navbar1
{	
	background-color: #000222;
	display: none;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 50px 0px lightblue;
	overflow: hidden;
	z-index: 997;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}
.searchbarlogo{
	height: auto;
	width: auto;
	display: flex;
}
.logo1{
	position: absolute;
	left: 0px;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchbartext{

}
.searchbarimg{

}
/*.searchlogo{
	width:6vw;
	height:5vh;
	margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbar{
	position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	height: 6.3vh;
	margin-top: 4vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}*/
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
	.navbar1
{	
	background-color: #000222;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 50px 0px lightblue;
	overflow: hidden;
	z-index: 999;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}
.searchbarlogo{
	height: auto;
	width: auto;
}
.logo1{
	position: absolute;
	left: 0px;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchlogo{
	width:5vh;
	height:5vh;
	margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbar{
	position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	height: 6.3vh;
	margin-top: 4vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
}
}
/*For Laptops and small screen:*/
@media(min-width: 869px) and (max-width: 1023px){
.navbar1
{	
	background-color: #000222;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 30px 0px lightblue;
	overflow: hidden;
	z-index: 999;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}

.logo1{
	position: absolute;
	left: 0px;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchbarlogo{
	/*background-color: red;*/
	/*position: absolute;*/
	margin-left: 30vw;
	height: auto;
	width: 20vw;
	display: flex;
}
.searchbartext{
	/*position: absolute;*/
	/*top: 10px;
	left: 0px;*/
	/*background-color: green;*/
	height: 5vh;
	width: auto;
}
.searchbarimg{
	/*position: absolute;*/
	/*right: 0px;
	top: 0px;*/
	margin-top: 0px;
	/*background-color: blue;*/
	height: 5vh;
	width: auto;
	
}
.searchlogo{
	/*position: absolute;*/
	height:5vh;
	background-color: white;
	margin-top: 5vh;
	object-fit: fill;
	border-right: 2vw solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	/*margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;*/
}
.searchbar{
	/*height: 6.3vh;*/
	/*position: absolute;*/
	/*position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	margin-top: 4vh;*/
	height: 5vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
}
}

@media(min-width: 1024px){
.navbar1
{	
	background-color: #000222;
	width: 100%;
	min-height: 15vh;
	color: white;
	vertical-align: middle;
	text-align: center;
	position: fixed;
	top: 0px;
	margin: 0px;
	line-height: 15vh;
	/*display: inline-flex;
	justify-content: flex-end;*/
	box-shadow: 0px 0px 30px 0px lightblue;
	overflow: hidden;
	z-index: 999;

/*	border-bottom-left-radius: 100px;
	border-bottom-right-radius: 100px;*/
/*	margin-top: 10px;
	border-radius: 120px;*/
}
body{
	margin: 0;
	padding: 0;
}

.logo1{
	position: absolute;
	left: 0px;
	display: flex;
	margin-bottom: 2vh;
	/*background-color: gray;*/
	top: 0px;
	vertical-align: middle;
	margin-left: 20px;
	font-family: Great Vibes;
	font-size: 4rem;
	transition: all 0.1s linear;
}
.logo1:hover{
	color: orange;
}
.signup1{
	/*position: absolute;
	right: 0px;*/
	border-radius: 50px;
	margin-right: 2vw;
	min-width: 10vw;
	height: 5vh;
	margin-top: 5vh;
	background-color: orange;
	color: #000222;
	border: none;

}
.contact1{
	/* position: absolute;*/
	 /*right: 10vw;*/
}
.about1{
	/*position: absolute;*/
	/*right: 20vw;*/
}
.explore1{
	/*position: absolute;*/
	/*right: 30vw;*/
}
.blog1{
	/*position: absolute;*/
	/*right: 40vw;*/
}
.hme1{
	/*position: absolute;
	right: 50vw;*/
}
.searchbarlogo{
	/*background-color: red;*/
	/*position: absolute;*/
	margin-left: 30vw;
	height: auto;
	width: 20vw;
	display: flex;
}
.searchbartext{
	/*position: absolute;*/
	/*top: 10px;
	left: 0px;*/
	/*background-color: green;*/
	height: 5vh;
	width: auto;
}
.searchbarimg{
	/*position: absolute;*/
	/*right: 0px;
	top: 0px;*/
	margin-top: 0px;
	/*background-color: blue;*/
	height: 5vh;
	width: auto;
	
}
.searchlogo{
	/*position: absolute;*/
	height:5.1vh;
	background-color: white;
	margin-top: 4.9vh;
	object-fit: fill;
	border-right: 2vw solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
	/*margin-top: 4vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	left: 30vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;*/
}
.searchbar{
	/*height: 6.3vh;*/
	/*position: absolute;*/
	/*position: absolute;
	left: 15vw;
	margin-left: 20px;
	min-width: 15vw;
	margin-top: 4vh;*/
	height: 5vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogo:hover{
	border-right: 30px solid green;
}
.menu{
	font-size: 1rem;
	transition: all 0.1 linear;
	margin-right: 2.5vw;
}
.menu:hover{
	color: lightblue;
	border-bottom: 2px solid lightblue;
	cursor: pointer;
}
.navbarmenu{
	position: absolute;
	right: 0px;
	top: 0px;
	/*background-color: gray;*/
}
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: red;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: top;
  height: 20%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 50%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
  height: 50%;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
  height: 50%;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  height: 50%;
}
/*For Mobile Phones:*/
@media(min-width: 40px){
	.DashboardNavbarSection{
		display: none;
	}
}
@media(min-width: 240px) and (max-width: 480px){
.dp{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_bigscreen{
	display: none;
}
.dptext{
	/*margin-left: 10vh;*/
	/*margin-top: 30vh;*/
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
	/*left: 10vw;*/
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.started{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	margin: 10px;
	margin-top: 3vh;
	position: absolute;
	left: 0px;
}
.overlay0{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.dp{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_bigscreen{
	display: none;
}
.dptext{
	/*margin-left: 10vh;*/
	/*margin-top: 30vh;*/
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
	/*left: 10vw;*/
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.started1btnold{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	margin: 10px;
	margin-top: 3vh;
	position: absolute;
	left: 0px;
}
.overlay0{
	background-size: cover;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}


@media(min-width: 869px) and (max-width: 1024px){
.dp{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_smallscreen{
	display: none;
}
.dptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.started1btnold{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
.overlay0{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}


/*For Laptops:*/
@media(min-width: 1025px){
.dp{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.nav_smallscreen{
	display: none;
}
.dptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.dptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.dptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.dptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.started1btnold{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
	outline: none;
	border: 5px solid white;
}
.overlay0{
	background-size: cover;
	height: 110vh;
	object-fit: cover;
	width: 100%;
	position: absolute;
	background-color: black;
  	opacity: 0.5;
  	/*margin-top: -170px;*/
}
}
/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.dblog{
	height: 120vh;
	display: grid;
	grid-gap: 0px;
}
.left{
	height: 30vh;
	width: 100%;
	/*background-color: red;*/
	vertical-align: middle;
	justify-content: center;
}
.right{
	height: 90vh;
	width: 100%;
	/*background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	width: 100%;
	text-align: center;
	/*margin-top: 1vh;*/
}
.dptext5{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
	/*margin-top: -40px;*/
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 1rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	/*right: 25vw;*/
	height: 90vh;
	width: 100%;
	object-fit: fill;
	/*top: 40vh;*/
}
.dblog2{
	position: absolute;
	/*right: 20vw;*/
	height: 90vh;
	width: 100%;
	/*top: 20vh;*/
	object-fit: fill;
	
}
.dblog3{
	position: absolute;
	/*top: 0px;
	right: 15vw;*/
	height: 90vh;
	width: 100%;
	object-fit: cover;
	
}
.dblog3 img{
	position: absolute;
	height: 60vh;
	width: 50vw;
	left: 25vw;
}
.dblog2 img{
	height: 50vh;
	width: 40vw;
	position: absolute;
	left: 30vw;
	top: 20vh;
}
.dblog1 img{
	height: 40vh;
	width: 30vw;
	position: absolute;
	left: 35vw;
	top: 45vh;
}
.dblogimg{
	height: 80vh;
	width: 100%;
}

.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}

@media(min-width: 481px) and (max-width: 868px){
.dblog{
	height: 120vh;
	display: grid;
	grid-gap: 0px;
}
.left{
	height: 30vh;
	width: 100%;
	/*background-color: red;*/
	vertical-align: middle;
	justify-content: center;
}
.right{
	height: 90vh;
	width: 100%;
	/*background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	width: 100%;
	text-align: center;
	/*margin-top: 1vh;*/
}
.dptext5{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: center;
	font-family: Robotoo;
	font-size: 2rem;
	/*margin-top: -40px;*/
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 1rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	/*right: 25vw;*/
	height: 90vh;
	width: 100%;
	object-fit: fill;
	/*top: 40vh;*/
}
.dblog2{
	position: absolute;
	/*right: 20vw;*/
	height: 90vh;
	width: 100%;
	/*top: 20vh;*/
	object-fit: fill;
	
}
.dblog3{
	position: absolute;
	/*top: 0px;
	right: 15vw;*/
	height: 90vh;
	width: 100%;
	object-fit: cover;
	
}
.dblog3 img{
	position: absolute;
	height: 60vh;
	width: 50vw;
	left: 25vw;
}
.dblog2 img{
	height: 50vh;
	width: 40vw;
	position: absolute;
	left: 30vw;
	top: 20vh;
}
.dblog1 img{
	height: 40vh;
	width: 30vw;
	position: absolute;
	left: 35vw;
	top: 45vh;
}
.dblogimg{
	height: 80vh;
	width: 100%;
}

.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}



/*For Tablets and iPads:*/
@media(min-width: 869px) and (max-width: 1024px){
.dblog{
	height: 100vh;
}
.left{
	height: 100vh;
	width: 50%;
	position: absolute;
	left: 0px;
	/*background-color: red;*/

}
.right{
	height: 100vh;
	width: 50%;
	position: absolute;
	right: 0px;
	/*background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	margin-left: 2vw;
	margin-top: 15vh;
}
.dptext5{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
	margin-top: -40px;
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 2rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	right: 26vw;
	height: 30vh;
	width: 20vw;
	object-fit: fill;
	top: 30vh;
	

}
.dblog2{
	position: absolute;
	right: 12vw;
	top: 28vh;
	height: 40vh;
	width: 25vw;
	object-fit: fill;
	
}
.dblog3{
	position: absolute;
	right: 2vw;
	top: 25vh;
	height: 50vh;
	width: 30vw;
	object-fit: fill;
	
}
.dblogimg{
	height: 100vh;
	width: 100%;
}
/*.odb1{
	z-index: 5;
	position: absolute;
	background-color: black;
	opacity: 0.1;
	right: 0vw;
	height: 38vh;
	width: 25vw;
	object-fit: cover;
	margin-top: -38vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb2{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vw;
	height: 47vh;
	width:35vw;
	object-fit: fill;
	margin-top: -47vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb3{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vw;
	height: 56.6vh;
	width:45vw;
	object-fit: fill;
	margin-top: -56.6vh;
	box-shadow: 5px 5px 5px 5px black;
}*/
.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}



/*For Laptops:*/
@media(min-width: 1025px){
.dblog{
	height: 120vh;
}
.left{
	height: 100vh;
	width: 50%;
	position: absolute;
	left: 0px;
/*	background-color: red;*/

}
.right{
	height: 100vh;
	width: 50%;
	position: absolute;
	right: 0px;
/*	background-color: green;*/
}
.dptext0{
	color: #0F2CB3;
	margin-left: 10vh;
	margin-top: 30vh;
}
.dptext5{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
}
.dptext6{
	color: #0F2CB3;
	text-align: left;
	font-family: Robotoo;
	font-size: 4rem;
	margin-top: -40px;
}
.started1{
	border-radius: 50px;
	border-width: 4px;
	border-color: #0F2CB3;
	font-family: Robotoo;
	background-color: transparent;
	padding: 8px;
	font-size: 2rem;
	text-align: center;
	justify-content: center;
}
.start{
	color: #0F2CB3;
}
.dblog1{
	position: absolute;
	right: 50vh;
	height: 40vh;
	width: 25vh;
	object-fit: fill;
	top: 8vh;
	

}
.dblog2{
	position: absolute;
	right: 25vh;
	height: 40vh;
	width: 35vh;
	object-fit: fill;
	top: 4vh;
	
}
.dblog3{
	position: absolute;
	right: 4vh;
	height: 60vh;
	width: 45vh;
	object-fit: fill;
	
}
.dblogimg{
	margin-top: 25vh;
	margin-right: 25vh;
}
.odb1{
	z-index: 5;
	position: absolute;
	background-color: black;
	opacity: 0.1;
	right: 0vh;
	height: 38vh;
	width: 25vh;
	object-fit: cover;
	margin-top: -38vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb2{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vh;
	height: 47vh;
	width:35vh;
	object-fit: fill;
	margin-top: -47vh;
	box-shadow: 5px 5px 5px 5px black;
}
.odb3{
	position: absolute;
	background-color: black;
	opacity: 0.2;
	right: 0vh;
	height: 56.6vh;
	width:45vh;
	object-fit: fill;
	margin-top: -56.6vh;
	box-shadow: 5px 5px 5px 5px black;
}
.shadowbox3{
	box-shadow: 5px 5px 5px 5px #808080;
}
.shadowbox2{
	box-shadow: 2.5px 2.5px 2.5px 2.5px black;
}
.shadowbox1{
	box-shadow: 2px 2px 2px 2px black;
}
}

/*For mobile phones:*/
@media(min-width: 240px) and (max-width: 480px){
.dal{
	height: auto;
	width: 100%;
	display: grid;
	grid-gap: 0px;
}
.lefta{
	height: auto;
	width: 100vw;
	/*position: absolute;
	left: 0px;*/
	background-color: white;
	border-top: 5px solid #000222;
}
.righta{
	height: auto;
	width: 100vw;
	color: black;
	/*position: absolute;
	right: 0px;*/
	background-color: #000222;
	/*border-top: 5px solid white;*/
	display: grid;
	align-items: center;
	justify-content: space-around;
}
.aboutussummary{
	display: inline;
	width: 100%;
	font-family: Montserrat;
	/*font-size: 0.5em;*/
}
.aboutp{
	width: 85vw;
	margin: 7.5vw;
	/*margin-left: 5vw;*/
	font-family: Montserrat;
	font-size: 1rem;
	text-align: justify;
	color: black;
}
.founderpicimg1{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg2{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg3{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic2{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
	margin-bottom: 5vh;
}
.namefoundertext1{
	font-family: Montserrat;
	color: black;
}
.foundertext1{
	font-family: Montserrat;
	color: black;
}
.namefoundertext2{
	font-family: Montserrat;
	color: black;
}
.foundertext2{
	font-family: Montserrat;
	color: black;
}
.namefoundertext3{
	font-family: Montserrat;
	color: black;
}
.foundertext3{
	font-family: Montserrat;
	color: black;
}
.read-or-hide{
	color: blue;
	cursor: pointer;
	font-family: cursive;
	font-size: 0.5rem;
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 1023px){
.dal{
	height: auto;
	width: 100%;
	display: grid;
	grid-gap: 0px;
}
.lefta{
	height: auto;
	width: 100vw;
	/*position: absolute;
	left: 0px;*/
	background-color: white;
	border-top: 5px solid #000222;
}
.righta{
	height: auto;
	width: 100vw;
	color: black;
	/*position: absolute;
	right: 0px;*/
	background-color: #000222;
	/*border-top: 5px solid white;*/
	display: grid;
	align-items: center;
	justify-content: space-around;
}
.aboutussummary{
	display: inline;
	width: 100%;
	font-family: Montserrat;
	/*font-size: 0.5em;*/
}
.aboutp{
	width: 85vw;
	margin: 7.5vw;
	/*margin-left: 5vw;*/
	font-family: Montserrat;
	font-size: 1.0rem;
	text-align: justify;
	color: black;
}
.founderpicimg1{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg2{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpicimg3{
	/*border-radius: 20vw;*/
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 60vw;
	width: 75vw;
	margin-top: 2.5vh;
	object-fit: fill;
}
.founderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic1{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
}
.cofounderpic2{
	height: auto;
	width: 87vw;
	background-color: white;
	border-radius: 15px;
	margin-top: 5vh;
	margin-bottom: 5vh;
}
.namefoundertext1{
	font-family: Montserrat;
	color: black;
}
.foundertext1{
	font-family: Montserrat;
	color: black;
}
.namefoundertext2{
	font-family: Montserrat;
	color: black;
}
.foundertext2{
	font-family: Montserrat;
	color: black;
}
.namefoundertext3{
	font-family: Montserrat;
	color: black;
}
.foundertext3{
	font-family: Montserrat;
	color: black;
}
.read-or-hide{
	color: blue;
	cursor: pointer;
	font-family: cursive;
	font-size: 0.5em;
}
}
/*For Laptops:*/
@media(min-width: 1024px){
.dal{
	height: 110vh;
	width: 100%;
}
.lefta{
	height: 110vh;
	width: 50vw;
	position: absolute;
	left: 0px;
	background-color: white;
	border-top: 5px solid #000222;
}
.aboutussummary{
	display: inline;
	width: 100%;
	font-family: Montserrat;
	/*font-size: 0.5em;*/
}
.righta{
	height: 110vh;
	width: 50vw;
	position: absolute;
	right: 0px;
	color: white;
	background-color: #000222;
	/*border-top: 5px solid white;*/
}
.aboutp{
	width: 40vw;
	margin-left: 5vw;
	font-size: 1.3rem;
	text-align: justify;
	color: black;
}
.founderpicimg1{
	height: 30vh;
	width: 22vw;
	position: absolute;
	left: 0vw;
	object-fit: fill;
}
.founderpicimg2{
	height: 30vh;
	width: 22vw;
	position: absolute;
	right: 0vw;
	object-fit: fill;
}
.founderpicimg3{
	height: 30vh;
	width: 22vw;
	position: absolute;
	left: 0vw;
	object-fit: fill;
}
.founderpic1{
	position: absolute;
	margin: 3vw;
	height: 30vh;
	width: 44vw;
	top: 0vh;
	left: 0vw;
}
.cofounderpic1{
	position: absolute;
	top: 30vh;
	height: 30vh;
	width: 44vw;
	right: 0vw;
	margin: 3vw;
}
.cofounderpic2{
	position: absolute;
	top: 60vh;
	height: 30vh;
	width: 44vw;
	left: 0vw;
	margin: 3vw;
}
.namefoundertext1{
	position: absolute;
	top: 5vh;
	right: 8vw;
}
.foundertext1{
	position: absolute;
	top: 10vh;
	right: 7.5vw;
}
.namefoundertext2{
	position: absolute;
	top: 5vh;
	left: 9vw;
}
.foundertext2{
	position: absolute;
	top: 10vh;
	left: 9vw;
}
.namefoundertext3{
	position: absolute;
	top: 8vh;
	right: 8vw;
}
.foundertext3{
	position: absolute;
	top: 13vh;
	right: 7.5vw;
}
.read-or-hide{
	color: blue;
	cursor: pointer;
	font-family: cursive;
	font-size: 0.5rem;
}
}

/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.dexplore{
	height:250vh;
	background-color: #000222;
	width: 100%;
}
.searchlogoexplore{
	width:6vw;
	height:4.7vh;
	margin-top: 10vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	right: 20vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbarexplore{
	position: absolute;
	left: 17vw;
	width: 50vw;
	margin-left: 20px;
	min-width: 50vw;
	height: 6.2vh;
	margin-top: 10vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogoexplore:hover{
	border-right: 30px solid green;
}
.expsearch{
	background-color: #000222;
	width: 100%;
	height: 20vh;

}
.dexplorespots{
	width: 80vw;
	position: absolute;
	left: 10vw;
	height: 50vh;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
	/*padding: 10px;*/
	border-radius: 50px;
	object-fit: cover;
	overflow: hidden;
		/*border: 2px solid white;*/
}
.more{
	margin-top: 165vh;
	background-color: green;
	width: 100%;
}
.moret{
	position: absolute;
	right: 20vw;
	margin-top: 30px;
}
.spots{
	/*background-color: green;*/
	position: relative;
	height: 50vh;
	width: 80vw;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
}
.spotsoverlay{
	z-index: 2;
	height: 20vh;
	width: 86vw;
	position: absolute;
	top: 31.5vh;
	left: 7vw;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	/*margin: 20px;
	padding: 10px;*/
	opacity: 0.5;
	background-color : black;
	-webkit-filter: blur(20px);
	        filter: blur(20px);
}
.dexploreimg{
	position: relative;
	height: 50vh;
	width: 80vw;
	display: grid;
	grid-gap: 5vh;
}
.spotsoverlaytext{
	position: absolute;
	top : 35vh;
	left: 10vw;
	text-align: center;
	font-size: 2rem;
	z-index: 3;
	width: 80vw;
}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 1023px){
.dexplore{
	height:250vh;
	background-color: #000222;
	width: 100%;
}
.searchlogoexplore{
	width:6vw;
	height:4.3vh;
	margin-top: 10vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	right: 20vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbarexplore{
	position: absolute;
	left: 17vw;
	width: 50vw;
	margin-left: 20px;
	min-width: 50vw;
	height: 5.9vh;
	margin-top: 10vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogoexplore:hover{
	border-right: 30px solid green;
}
.expsearch{
	background-color: #000222;
	width: 100%;
	height: 20vh;

}
.dexplorespots{
	width: 80vw;
	position: absolute;
	left: 10vw;
	height: 50vh;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
	/*padding: 10px;*/
	border-radius: 50px;
	object-fit: cover;
	overflow: hidden;
		/*border: 2px solid white;*/
}
.more{
	margin-top: 165vh;
	background-color: green;
	width: 100%;
}
.moret{
	position: absolute;
	right: 20vw;
	margin-top: 30px;
}
.spots{
	/*background-color: green;*/
	position: relative;
	height: 50vh;
	width: 80vw;
	/*margin-top: 5vh;*/
	/*margin: 20px;*/
}
.spotsoverlay{
	z-index: 2;
	height: 20vh;
	width: 80vw;
	position: absolute;
	top: 31.5vh;
	left: 1.2vw;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	/*margin: 20px;
	padding: 10px;*/
	opacity: 0.5;
	background-color : black;
	-webkit-filter: blur(20px);
	        filter: blur(20px);
}
.dexploreimg{
	position: relative;
	height: 50vh;
	width: 80vw;
	display: grid;
	grid-gap: 5vh;
}
.spotsoverlaytext{
	position: absolute;
	top : 35vh;
	left: 10vw;
	text-align: center;
	font-size: 2rem;
	z-index: 3;
	width: 80vw;
}
}
/*For Laptops:*/
@media(min-width: 1024px){
.dexplore{
	height:115vh;
	background-color: #000222;
	width: 100%;
}
.searchlogoexplore{
	width:3vw;
	height:5vh;
	margin-top: 20vh;
	background-color: white;
	border-color: transparent;
	position: absolute;
	right: 20vw;
	margin-left: 20px;
	padding: 5px;
	transition: all 0.1 linear ;
	border-right: 30px solid orange;
	border-top-right-radius: 50px;
	border-bottom-right-radius: 50px;
}
.searchbarexplore{
	position: absolute;
	left: 25vw;
	margin-left: 20px;
	min-width: 50vw;
	height: 6.3vh;
	margin-top: 20vh;
	background-color: white;
	color: black;
	border: none;
	outline: none;
	border-top-left-radius: 50px;
	border-bottom-left-radius: 50px;
}
.searchlogoexplore:hover{
	border-right: 30px solid green;
}
.expsearch{
	/*background-color: green;*/
	width: 100%;
	height: 30vh;

}
.dexplorespots{
	width: 40vw;
	height: 50vh;
	margin: 20px;
	padding: 10px;
	border-radius: 50px;
	object-fit: cover;
	overflow: hidden;
		/*border: 2px solid white;*/
}
/*.imgblur{
	/*position: absolute;*/
/*	z-index: 5;
	width: 40vh;*/
	/*border-bottom-right-radius: 50px;
	border-bottom-left-radius: 50px;
	width: 40vh;
	height: 18vh;
	margin: 30px;
	padding: 10px;
	opacity: 0.6;
	filter: blur(10px);
	background-color: black;
	background-position: -200px ;
}*/
/*.imgblur0{
	margin-top: -26vh;
}*/*/
.more{
	margin-top: 5vh;
	background-color: green;
	width: 100%;
}
.moret{
	position: absolute;
	right: 20vw;
	margin-top: 30px;
}
.spots{
	/*background-color: green;*/
	position: relative;
	height: 50vh;
	width: 20vw;
	margin: 20px;
	display: inline-block;
}
.spotsoverlay{
	z-index: 2;
	height: 20vh;
	width: 20vw;
	position: absolute;
	top: 31.5vh;
	left: 1.2vw;
	border-bottom-right-radius: 40px;
	border-bottom-left-radius: 40px;
	margin: 20px;
	padding: 10px;
	opacity: 0.5;
	background-color : black;
	-webkit-filter: blur(20px);
	        filter: blur(20px);
}
.dexploreimg{
	position: relative;
	height: 50vh;
	width: 100%;
	display: inline-block;
}
/*.spotsoverlay{
	position: absolute;
	top: 25vh;
	left: 4vh;
	z-index: 5;
	transform: rotate(-51.34deg);
	font-size: 3em;
	width: 40vh;
}*/
.spotsoverlaytext{
	position: absolute;
	top : 35vh;
	left: 2vw;
	font-size: 2rem;
	z-index: 3;
	width: 20vw;
}
}

/*For mobile device :*/
body{
	min-height: 100vh;
}
@media(min-width: 240px) and (max-width: 480px){
/*body{
	min-height: 100vh;
}*/
.write{
	min-height: 80vh;
	position: relative;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
}
.writebac{
	min-height: 70vh;
	display: grid;
	margin: 6%;
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(auto-fill , minmax(240px , 480px));
	/*display: grid;
	grid-template-columns: 1fr;*/
}
/*.writeus{
	min-height: 0.2fr;
	font-family: Montserrat;
	width: 80%;
	background-color: #000222;
	min-height: 10vh;
}*/
.writeus1{
	font-family: Great Vibes;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	font-weight: bold;
	font-size: 2rem;
	padding: 2vh;
	min-height: 10vh;
	vertical-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	background-color: #000222;
}
.zone{
	min-height: 50vh;
	font-family: Montserrat;
	color: black;
	/*font-weight: bold;*/
	font-size: 0.8rem;
	display: grid;
	grid-gap: 0px;
	grid-template-columns: repeat(auto-fill , , minmax(240px , 480px));
}
.writeleft{
	background-color: #F5F5F5;
	min-height: 30vh;
	/*min-height: 0.3fr;*/
}
.dataproblem{
	min-height: 25vh;
	margin-top: 2.5vh;
	font-size: 0.8rem;
	display: grid;
}
.writeright{
	background-color: green;
	min-height: 20vh;
	/*min-height: 0.3fr;*/
}
.mailbox{
	display: none;
}
.mailbox1{
	background-color: #F5F5F5;
	min-height: 6vh;
}
.mailbox1 label{
	font-size: 0.8rem;
}
.prblm{
	background-color: #F5F5F5;
}
.problem{
	min-height: 10vh;
	width: 80%;
	margin: 2vh;
	outline: none;
	border: none;
}
.submitbtn{
	background-color: #000222;
	/*width : 80%;*/
	min-height: 10vh;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	/*min-height: 0.2fr;*/
}
.submitbutton{
	min-height: 6vh;
	width: 32%;
	border-radius: 50px;
	margin-top: 2vh;
}
}
/*For Tablets:*/
@media(min-width: 481px) and (max-width: 1023px){
	.write{
	min-height: 80vh;
	position: relative;
	/*display: flex;
	align-items: center;
	justify-content: center;*/
}
.writebac{
	min-height: 70vh;
	display: grid;
	margin: 6%;
	align-items: center;
	justify-content: center;
	grid-template-columns: repeat(auto-fill , minmax(240px , 480px));
	/*display: grid;
	grid-template-columns: 1fr;*/
}
/*.writeus{
	min-height: 0.2fr;
	font-family: Montserrat;
	width: 80%;
	background-color: #000222;
	min-height: 10vh;
}*/
.writeus1{
	font-family: Montserrat;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	font-weight: bold;
	font-size: 2rem;
	padding: 2vh;
	min-height: 10vh;
	vertical-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
	background-color: #000222;
}
.zone{
	min-height: 50vh;
	display: grid;
	grid-gap: 0px;
	grid-template-columns: repeat(auto-fill , , minmax(240px , 480px));
}
.writeleft{
	background-color: #F5F5F5;
	min-height: 30vh;
	/*min-height: 0.3fr;*/
}
.dataproblem{
	min-height: 25vh;
	margin-top: 2.5vh;
	font-size: 1rem;
	display: grid;
}
.writeright{
	background-color: green;
	min-height: 20vh;
	/*min-height: 0.3fr;*/
}
.mailbox{
	display: none;
}
.mailbox1{
	background-color: #F5F5F5;
	min-height: 6vh;
}
.mailbox1 label{
	font-size: 1.2rem;
}
.prblm{
	background-color: #F5F5F5;
}
.problem{
	min-height: 10vh;
	width: 80%;
	margin: 2vh;
	outline: none;
	border: none;
}
.submitbtn{
	background-color: #000222;
	/*width : 80%;*/
	min-height: 10vh;
	border-bottom-right-radius: 15px;
	border-bottom-left-radius: 15px;
	/*min-height: 0.2fr;*/
}
.submitbutton{
	min-height: 6vh;
	width: 32%;
	border-radius: 50px;
	margin-top: 2vh;
}
}
/*For Laptops:*/
@media(min-width: 1024px){
.write{
	min-height: 100vh;
	/*margin-top: 5vh;*/
	margin-bottom: 5vh;
	width: 100%;
	background-color: white;
	position: relative;
}
.writebac{
	min-height: 80vh;
	width: 80vw;
	background-color: white;
	position: absolute;
	left: 10vw;
	margin-top: 10vh;
}
.writeus1{
	background-color: #000222;
	min-height: 10vh;
	font-family: Montserratt;
	font-size: 1.5rem;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	font-weight: bold;
	font-size: 2rem;
	padding: 2vh;
	vertical-align: center;
	align-items: center;
	justify-content: center;
	align-content: center;
}
.zone{
	min-height: 60vh;
}
.writeleft{
	width: 50%;
	position: absolute;
	left: 0px;
	background-color: #F5F5F5;
	min-height: 60vh;
}
.dataproblem{
	min-height: 35vh;
	margin-top: 5vh;
	font-size: 1rem;
	display: grid;
}
.writeright{
	width: 50%;
	position: absolute;
	right: 0px;
	background-color: #F5F5F5;
	min-height: 60vh;
}
.submitbtn{
	background-color: #000222;
	width : 100%;
	min-height: 10vh;
}
.prblm{
}
.problem{
	min-height: 30vh;
	width: 25vw;
	margin: 15vh;
	outline: none;
	border: none;
}
.mailbox{
	background-color: #F5F5F5;
	min-height: 6vh;
	margin: 3vh;
}
.mailbox label{
	font-size: 1.2rem;
}
.mailbox1{
	display: none;
}
.submitbutton{
	min-height: 6vh;
	width: 10vw;
	border-radius: 50px;
	margin-top: 2vh;
	font-size: 1.2rem;
}
}
/*Especially For Jio phone:
/*@media(max-width: 239px){

}*/
/*For Laptops:*/
@media(min-width: 1024px){
.footer{
	height: 10vh;
	width: 100%;
	background-color: #000222;
	box-shadow: 50px 50px 50px 50px #808080;
	margin-top: 10vh;
	z-index: 10;
	position: relative;
	bottom: 0px;
	border: none;
}
.sent{
	/*position: absolute;*/
	/*margin-top: 4vh;
	margin-left: 60vh;*/
	height: 10vh;
	width: 100%;
	justify-content: center;
	text-align: center;
	margin: 0px;
	height: 10vh;
	vertical-align: center;
}
.sent p{
	text-align: center;
	justify-content: center;
	padding: 2vh;
	font-size: 0.9rem;
	margin: 2vh;
}
}
/*For mobile device:*/
@media(min-width: 240px) and (max-width: 480px){ 
	.sent{
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer{
		height: 15vh;
		width: 100%;
		background-color: #000222;
		box-shadow: 50px 50px 50px 50px #808080;
	/*	margin-top: 50px;
		margin-bottom: 0px;*/
		/*z-index: 10;*/
		position: relative;
		bottom: 0px;
	}
	.sent p{
		text-align: center;
		justify-content: center;
		padding: 3vh;
		font-size: 0.7rem;
		margin: 2vh;
	}
}
/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 1023px){
	.sent{
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer{
		height: 15vh;
		width: 100%;
		background-color: #000222;
		box-shadow: 50px 50px 50px 50px #808080;
		margin-top: 50px;
		z-index: 10;
		position: relative;
		bottom: 0px;
		margin-bottom: 0px;
	}
	.sent p{
		text-align: center;
		justify-content: center;
		padding: 3vh;
		font-size: 1rem;
		margin: 2vh;
	}
}
/*Especially For jio phone:*/
@media(max-width: 239px){
	.sent{
		width: 100%;
		justify-content: center;
		text-align: center;
	}
	.footer{
		height: 15vh;
		width: 100%;
		background-color: #000222;
		box-shadow: 50px 50px 50px 50px #808080;
		margin-top: 50px;
		margin-bottom: 0px;
		z-index: 10;
		position: relative;
		bottom: 0px;
	}
	.sent p{
		text-align: center;
		justify-content: center;
		padding: 3vh;
		font-size: 0.5rem;
		margin: 2vh;
	}
}
/*For Mobile Devices:*/
@media(min-width: 240px) and (max-width: 480px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.form_signin{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
  box-shadow: 2px 2px 2px 2px #2C2828;
  transition:all 0.08s linear;
  border-radius: 20px;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3vw;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:16vw;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.form_signin{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
  box-shadow: 2px 2px 2px 2px #2C2828;
  transition:all 0.08s linear;
  border-radius: 20px;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3vw;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:16vw;
}
}



@media(min-width: 869px) and (max-width: 1023px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
}
.form_signin{
  background: transparent;
  position: absolute;
  top: 10vh;
  height: auto;
  width: 50vw;
  right:5%;
  float: right;
  display: inline;
  box-shadow: 2px 2px 2px 2px #2C2828;
  border-radius: 10px;
  height: auto;
  width: auto;
  z-index: 3;
}
}

/*For Laptops:*/
@media(min-width: 1024px){
.loginpage{
  width: 100vw;
  height: 100vh;
}
.myvid
{
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}
.vaguss_text{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
.text:hover
{
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.slogan{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
}
.form_signin{
  background: transparent;
  position: absolute;
  top: 10vh;
  height: auto;
  width: auto;
  right:10%;
  float: right;
  padding: 2vh;
  display: inline;
  box-shadow: 2px 2px 2px 2px #2C2828;
  border-radius: 10px;
  height: auto;
  width: auto;
  z-index: 3;
}
}
/*
For Mobile Devices:*/
@media(min-width: 240px) and (max-width: 480px){
/*body{
	width: 100%;
}*/
.Form
{
	/*position: fixed;
	margin: 0px;
	height: 80vh;
	width: 80%;*/
	/*right:10%;
	top:-8vh;*/
	/*bottom: 50px;*/
	/*float: right;*/
	font-family: Montserrat;
	box-shadow: 0px 0px 0px 0px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 80vw;
	/*margin-top: 5vh;*/
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
	font-size: 1.5rem;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 60vw;
	height: auto;
	padding: 8px;
	font-family: Montserrat;

}
.Glogo{
	width: 4vw;
	margin-top:0px; 
	margin-right:2vw;
}
.Facebook{
	background-color: #0d66d0;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 8vw;
	margin-right: 2vw;

}
.flogotext{
	/*bottom: 0px;*/
	font-size: 0.7rem;
}
.Twitter{
	background-color: #1DA1F2;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 8vw;
	margin-right: 2vw;
}
.logotext{
	margin-bottom: 0px;
	bottom: 0px;
	font-size: 0.7rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.Form
{
	/*position: fixed;
	margin: 0px;
	height: 80vh;
	width: 80%;*/
	/*right:10%;
	top:-8vh;*/
	/*bottom: 50px;*/
	/*float: right;*/
	font-family: Montserrat;
	box-shadow: 0px 0px 0px 0px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 80vw;
	/*margin-top: 5vh;*/
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
	font-size: 1.5rem;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 60vw;
	height: auto;
	padding: 8px;
	font-family: Montserrat;

}
.Glogo{
	width: 4vw;
	margin-top:0px; 
	margin-right:2vw;
}
.Facebook{
	background-color: #0d66d0;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 8vw;
	margin-right: 2vw;

}
.flogotext{
	/*bottom: 0px;*/
	font-size: 0.7rem;
}
.Twitter{
	background-color: #1DA1F2;
	width: 60vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 8vw;
	margin-right: 2vw;
}
.logotext{
	margin-bottom: 0px;
	bottom: 0px;
	font-size: 0.7rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}
}


@media(min-width: 869px) and (max-width: 1023px){
.Form
{
	/*min-height: 60vh;
	min-width: 400px;
	right:10%;
	top:-8vh;
	bottom: 50px;
	float: right;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 40vw;
}
label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 28vw;
	height: auto;
	font-family: Montserrat;
	padding: 10px;

}
.Glogo{
	width: 20px;
	margin-top:0px; 
	margin-right:8px;
}
.Facebook{
	background-color: #0d66d0;
	width: 28vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 40px;
	margin-right: 8px;
}
.flogotext{
	/*bottom: 13px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.Twitter{
	background-color: #1DA1F2;
	width: 28vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 40px;
	margin-right: 8px;
}
.logotext{
	/*margin-bottom: 50px;
	bottom: 4px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}
}

/*For Laptops:*/
@media(min-width: 1024px){
.Form
{
	/*min-height: 60vh;
	min-width: 400px;
	right:10%;
	top:-8vh;
	bottom: 50px;
	float: right;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.form_container{
	height: auto;
	width: 35vw;
}
label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.Google{
	font-weight: bold;
	width: 25vw;
	height: auto;
	font-family: Montserrat;
	padding: 10px;

}
.Glogo{
	width: 20px;
	margin-top:0px; 
	margin-right:8px;
}
.Facebook{
	background-color: #0d66d0;
	width: 25vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.Flogo{
	width: 40px;
	margin-right: 8px;
}
.flogotext{
	/*bottom: 13px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.Twitter{
	background-color: #1DA1F2;
	width: 25vw;
	height: auto;
	font-weight: bold;
	font-family: Montserrat;
}
.tlogo{
	width: 40px;
	margin-right: 8px;
}
.logotext{
	/*margin-bottom: 50px;
	bottom: 4px;*/
	font-size: 0.8rem;
	display: block;
	position: relative;
}
.newuser{
	text-align: center;
	font-size: 0.8rem;
}	
}





/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_largescreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	background-size: cover;
	object-fit: cover;
	top: 0vh;
	width: 55vw;
	height: 100vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	/*position: absolute;*/
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
	/*margin-left: 10vh;
	margin-top: 30vh;*/
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin:10px;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.loggedinhomestarted{
	border-radius: 50px;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	margin: 10px;
	margin-top: 3vh;
	position: absolute;
	left: 0px;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_largescreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	background-size: cover;
	object-fit: cover;
	top: 0vh;
	width: 55vw;
	height: 100vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	/*position: absolute;*/
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	position: absolute;
	top: 20vh;
	margin-left: 5vw;
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 2rem;
	margin: 10px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1rem;
	margin: 10px;
	margin-top: 3vh;
}
.loggedinhomestarted{
	border-radius: 50px;
	width: 15vh;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_smallscreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	top: 10vh;
	width: 55vw;
	height: 90vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	position: absolute;
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.loggedinhomestarted{
	border-radius: 50px;
	width: 15vh;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
}

/*For large screens:*/
@media(min-width: 1024px){
.loggedin__home_mainpage{
	height: 100vh;
	width: 100vw;
}
.nav_smallscreen{
	display: none;
}
.loggedinhomeoverlaycard{
	position: absolute;
	top: 10vh;
	width: 55vw;
	height: 90vh;
	background-color: black;
	opacity: 0.5;
	z-index: 2;
	box-shadow: 50px 0px 50px 0px black;
}
.loggedinhomeimg{
	position: absolute;
	background-size: cover;
	z-index: 1;
	height: 100vh;
	object-fit: cover;
	width: 100%;
	/*margin-top: -170px;*/
}
.loggedin_blog_home_clickbtn{
	position: absolute;
	z-index: 999;
	top: 4vh;
	left: 62vw;
	height: 5vh;
	width: 20vh;
	/*background-color: #0d66d0;*/
	border: none;
}

.loggedin_blog_homepage{
	background-color: #0d66d0;
	border: none;
	width: 4vw;
	height: 4vh;
}
.loggedinhomedptext{
	margin-left: 10vh;
	margin-top: 30vh;
}
.loggedinhomedptext1{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
}
.loggedinhomedptext2{
	text-align: left;
	font-family: Montserratt;
	font-size: 4rem;
	margin-top: -40px;
}
.loggedinhomedptext3{
	text-align: left;
	font-family: Robotoo;
	font-size: 1.5rem;
	margin-top: -30px;
}
.loggedinhomestarted{
	border-radius: 50px;
	width: 15vh;
	border-width: 4px;
	border-color: white;
	font-family: Robotoo;
	background-color: transparent;
	padding: 15px;
	position: absolute;
	left: 0px;
}
}
/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.ShareBlogpage{
    width: 100vw;
    height: auto;
}
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    left: 0px;
    top: 0px;
    height: auto;
    width: 100%;
    /*margin-top: 5vh;
    margin-right: 5vw;*/
}

.card {
    position: absolute;
    left: 2vw;
    width: 96vw;
    height: 33vh;
    /*background-color: green;*/
   
}

/*.card:hover {
    background-color: #141414;
}*/
.sharebox{
    /*position: absolute;
    top: 0px;*/
    background-color: white;
    text-align: top-left;
    width: 96vw;
    height: 25vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 2vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 96vw;
    margin: 0px;
    margin-top: -0.4vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 5vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 25%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 6vh;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 5vh;
    width: 20vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}


/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.ShareBlogpage{
    width: 100vw;
    height: auto;
}
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.sharebox{
    background-color: white;
    text-align: top-left;
    width: 90vw;
    height: 25vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 5vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card {
    position: absolute;
    /*background-color: green;*/
    left: 5vw;
    width: 90vw;
    height: 35vh;
   
}

/*.card:hover {
    background-color: #141414;
}*/
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 90vw;
    margin: 0px;
    margin-top: -0.5vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 15%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 12vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}


/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.ShareBlogpage{
    width: 100vw;
    height: auto;
}
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.sharebox{
    background-color: white;
    text-align: top-left;
    width: 50vw;
    height: 35vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 5vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card {
    position: absolute;
    left: 5vw;
    width: 50vw;
    height: 35vh;
   
}

/*.card:hover {
    background-color: #141414;
}*/
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 50vw;
    margin: 0px;
    margin-top: -0.4vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 15%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}


/*For Large screens:*/
@media(min-width: 1024px){
.ShareBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.sharebox{
    background-color: white;
    text-align: top-left;
    width: 50vw;
    height: 35vh;
    margin: 0px;
    border: 5px solid grey;
    padding: 0px;
    outline: none;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: 5vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.card {
    position: absolute;
    left: 5vw;
    width: 50vw;
    height: 35vh;
   
}

/*.card:hover {
    background-color: #141414;
}*/
.shareBottomBar{
    position:absolute;
    line-height: 5vh;
    height: 5vh;
    width: 50vw;
    margin: 0px;
    margin-top: -0.3vh;
    border: none;
    outline: none;
    background-color: grey;
    justify-content: center;
    vertical-align: middle;
}
.shareBottomBar img{
    position: absolute;
    top: 0px;
    bottom: 0px;
    margin: auto;
    left:1vw;
    height: 4vh;
    line-height: 4vh;
    padding: 0px;
    vertical-align: middle;
   
}

.shareBottomBar button{
    position: absolute;
    right: 0px;
    line-height: 3vh;
    font-size: 0.8rem;
    vertical-align: middle;
    text-align: center;
    margin-right: 2vw;
    justify-content: center;    
    height: 4vh;
    min-width: 15%;
    background-color: #0d66d0;
    border: none;
}
.options-menu1{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu1 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu1 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
}





.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

.header-bg {
    background-color: rgba(0,0,0, .85);
    transition: .5s;
}

.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger {
    display: none;
}

@media screen and (max-width:1240px) {
    .hamburger {
        display: block;
    }

    .nav-menu {
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%;
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background: rgba(0,0,0, .9);
    }

    .nav-menu.active {
        left: 0;
    }

    .nav-menu li {
        padding: 1rem 0;
    }

    .nav-menu a {
        font-size: 2rem;
    }
}


/*For mobile phones:*/
@media(min-width: 240px) and (max-width: 480px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 10vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url(/static/media/blogtop.d553ab41.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 20vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url(/static/media/blogtop.d553ab41.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url(/static/media/blogtop.d553ab41.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For Large screens:*/
@media(min-width: 1024px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url(/static/media/blogtop.d553ab41.jpg) no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.TrendingStoriesCardSide{
	position: absolute;
	top: 40vh;
	left: 5vw;
	height: 50vh;
	width: 90vw;
	background-color: white;
	display: grid;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 5px 8px 5px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	position: absolute;
	top: 10vh;
	left: 0px;
	height: 35vh;
	width: 88vw;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	/*justify-content: space-around;*/
	/*background-color: green;*/
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 3vw;
	display: grid;
	grid-gap: 1vh;
	/*justify-content: space-around;*/
	height: 12vh;
	width: 35vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 35vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 35vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}


/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.TrendingStoriesCardSide{
	position: absolute;
	background-color: blue;
	top: 55vh;
	left: 5vw;
	height: 50vh;
	width: 90vw;
	background-color: white;
	display: grid;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 5px 8px 5px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	position: absolute;
	top: 10vh;
	left: 0px;
	height: 35vh;
	width: 88vw;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	/*justify-content: space-around;*/
	/*background-color: green;*/
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 3vw;
	display: grid;
	grid-gap: 1vh;
	/*justify-content: space-around;*/
	height: 12vh;
	width: 35vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 35vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 35vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}


/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.TrendingStoriesCardSide{
	height: 90vh;
	width: 30vw;
	background-color: white;
	display: grid;
	grid-gap: 1vh;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 0px 8px 0px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	overflow-y: scroll;
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 1vw;
	display: flex;
	justify-content: space-between;
	height: 12vh;
	width: 28vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 8vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 19vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}


/*For large screens:*/
@media(min-width: 1024px){
.TrendingStoriesCardSide{
	height: 90vh;
	width: 30vw;
	background-color: white;
	display: grid;
	grid-gap: 1vh;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 0px 8px 0px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	overflow-y: scroll;
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 1vw;
	display: flex;
	justify-content: space-between;
	height: 12vh;
	width: 28vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 8vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 19vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}



/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.register_page{
  height: 100vh;
  width: 100vw;
}
.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:18vw;
 }
 .vaguss_text2{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.register_page{
  height: 100vh;
  width: 100vw;
}
.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  height: auto;
  width: 80vw;
  position: absolute;
  margin: 10%;
  margin-top: 10vh;
  font-family: Montserrat;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  display: none;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 2;
  margin-top: 55vh;
  margin-bottom: 20vh;
  margin-left:18vw;
 }
 .vaguss_text2{
  position: absolute;
  top: 1vh;
  width: 100vw;
  margin: 0px;
  left: 0px;
  font-family: Great Vibes;
  font-size: 2rem;
  z-index: 2;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}

/*For Laptops:*/
@media(min-width: 869px) and (max-width: 1024px){
.register_page{
  width: 100vw;
  height: auto;
}

.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  background: #f6f4f4;
  height: auto;
  width: auto;
  right:10%;
  float: right;
  display: inline;
  height: auto;
  width: auto;
  z-index: 3;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
 }
 .vaguss_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}


@media(min-width: 1025px){
.register_page{
  width: 100vw;
  height: auto;
}

.bg1{
  object-fit: cover;
  background-color: black;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
}
.form_register{
  background: #f6f4f4;
  height: auto;
  width: auto;
  right:10%;
  float: right;
  display: inline;
  height: auto;
  width: auto;
  z-index: 3;
}
.overlay{
  object-fit: cover;
  background-color: black;
  opacity: 0.5;
  height:100vh;
  width:100vw;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1;
 }
 .slogan_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Roboto;
  font-size: 3rem;
  z-index: 3;
  text-align: center;
  top: 50vh;
 }
 .vaguss_text2{
  width: 50%;
  height: auto;
  position: absolute;
  font-family: Great Vibes;
  font-size: 8rem;
  z-index: 3;
  text-align: center;
  top: 30vh;
  -webkit-animation: color-change 1s infinite;
          animation: color-change 1s infinite;
  transition:all 0.08s linear;
}
}
/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.Form
{
	/*background-color: #f6f4f4;
	min-height: 60vh;
	min-width: 400px;
	right:10%;
	margin-top: 15vh;
	float: right;
	display: inline;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.register_container{
	background-color: #f6f4f4;
	height: auto;
	width: 80vw;
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.already_accnt{
	text-align: center;
	font-size: 0.7rem;
	width: 100%;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.Form
{
	/*background-color: #f6f4f4;
	min-height: 60vh;
	min-width: 400px;
	right:10%;
	margin-top: 15vh;
	float: right;
	display: inline;*/
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}
.register_container{
	background-color: #f6f4f4;
	height: auto;
	width: 80vw;
}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	/*right: 10%;*/
	
}
.already_accnt{
	text-align: center;
	font-size: 0.7rem;
	width: 100%;
}
}

/*For Laptops:
*/
@media(min-width: 869px) and (max-width: 1024px){
.register_container
{
	background-color: #f6f4f4;
	position: absolute;
	right: 0px;
	margin-left: 0px;
	margin-right: 10%;
	margin-top: 8%;
	height: auto;
	width: auto;
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	right: 10%;
	
}
}

@media(min-width: 1025px){
.register_container
{
	background-color: #f6f4f4;
	position: absolute;
	right: 0px;
	margin-left: 0px;
	margin-right: 10%;
	margin-top: 8%;
	height: auto;
	width: auto;
	font-family: Montserrat;
	box-shadow: 2px 2px 2px 2px #2C2828;
	transition:all 0.08s linear;

}

label
{
	font-family: Montserrat;
}
.signin{
	font-family:Montserrat;
}
.box
{
	border: none;
	border-bottom: 2px solid #0d66d0 ;
	transition:all 0.08s linear;
	outline: none;
	background-color: transparent;
}
.box:hover
{
	border: none;
	border-bottom: 4px solid #0d66d0;
	outline: none;
}
.box:active
{
	border: none;
	border-bottom: 4px solid green;
	outline: none;
}
.signbox
{
	background-color: #0d66d0;
	border: none;
}
.homp
{
	right: 10%;
	
}	
}


/*For Mobile phones:*/
@media(min-width: 240px) and (max-width: 480px){
.ReadBlogpage{
    width: 100vw;
    height: auto;
}
.BlogReadCard{
    width: 100vw;
    height: 30vh;
    /*padding: 6rem 1rem;*/
    display: grid;
    overflow-y: scroll;
    grid-gap: 10vh;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.ReadBlog {
    width: 100vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogReadTitleCard{
   /* background: red;*/
    width: 96vw;
    height: 25vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogreadimg{
    height: 10vh;
    width: 90vw;
    object-fit: cover;
}
.BlogTextPart{
    /*background: green;*/
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.BlogTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.Blogreadtext{
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.linkread{
    /*position: absolute;*/
  /*  left: 50vw;*/
    margin-top: 2vh;
   /* background: gray;*/
    height: 4vh;
    width: 40vw;
}
.options-menu2{
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 6vh;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu2 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 5vh;
    width: 20vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu2 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.ReadBlogpage{
    width: 100vw;
    height: auto;
}
.BlogReadCard{
    width: 100vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 2vh;
    margin-top: 2vh;
    /*background-color: white;*/
}
.CardTrendingStory{
    position: absolute;
    left: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.ReadBlog {
    width: 100vw;
    height: 35vh;
    overflow-y: scroll;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogReadTitleCard{
    /*background: red;*/
    width: 96vw;
    height: auto;
    margin-left: 2vw;
    padding: 1rem;
    margin-top: 2vh;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogreadimg{
    height: 10vh;
    width: 96vw;
    object-fit: cover;
}
.BlogTextPart{
   /* background: green;*/
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.BlogTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linkread{
    /*position: absolute;
    left: 50vw;*/
    /*background: black;*/
    height: 2vh;
    width: 50vw;
}
.options-menu2{
    position: absolute;
    top: 12vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu2 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu2 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.ReadBlogpage{
    width: 100vw;
    height: auto;
}
.BlogReadCard{
    width: 100vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 2vh;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.ReadBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogReadTitleCard{
    /*background: red;*/
    width: 60vw;
    height: 25vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogreadimg{
    height: 10vh;
    width: 60vw;
    object-fit: cover;
}
.BlogTextPart{
   /* background: green;*/
    height: 10vh;
    width: 55vw;
    text-align: justify;
}
.BlogTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linkread{
    position: absolute;
    left: 50vw;
    /*background: black;*/
    height: 2vh;
    width: 50vw;
}
.options-menu2{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu2 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu2 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For Large screens:*/
@media(min-width: 1024px){
.ReadBlogpage{
    width: 100vw;
    height: auto;
}
.BlogReadCard{
    width: 100vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 2vh;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.ReadBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogReadTitleCard{
    /*background: red;*/
    width: 60vw;
    height: 25vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogreadimg{
    height: 10vh;
    width: 60vw;
    object-fit: cover;
}
.BlogTextPart{
   /* background: green;*/
    height: 10vh;
    width: 55vw;
    text-align: justify;
}
.BlogTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linkread{
    position: absolute;
    left: 50vw;
    /*background: black;*/
    height: 2vh;
    width: 10vw;
}
.options-menu2{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu2 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu2 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}


/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.SavedBlogpage{
    width: 100vw;
    height: auto;
}
.BlogSavedCard{
    width: 100vw;
    height: 30vh;
    /*padding: 6rem 1rem;*/
    overflow-y: scroll;
    display: grid;
    grid-gap: 2vh;
    /*grid-template-columns: 1fr 1fr;*/
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 96vw;
    height: 35vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 90vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    /*position: absolute;*/
    /*background: green;*/
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.BlogSavedtext{
    height: 10vh;
    width: 85vw;
    text-align: justify;
}
.linksaved{
    /*background: gray;*/
    /*position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;*/
    /*background: black;*/
    margin-top: 2vh;
    height: 3vh;
    width: 40vw;
}
.options-menu3{
    position: absolute;
    top: 3vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 6vh;
    /*left: 1vw;*/
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 5vh;
    width: 20vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}


/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.SavedBlogpage{
    width: 100vw;
    height: auto;
}
.BlogSavedCard{
    width: 100vw;
    height: auto;
    /*margin-left: 5vw;*/
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 2vh;
    /*grid-template-columns: 1fr 1fr;*/
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: 35vh;
    overflow-y: scroll;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 96vw;
    height: auto;
    margin-left: 2vw;
    margin-top: 2vh;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 96vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    /*position: absolute;*/
    /*background: green;*/
    height: auto;
    width: 85vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linksaved{
    /*background: gray;*/
    /*position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;*/
    /*background: black;*/
    height: 3vh;
    width: 50vw;
}
.options-menu3{
    position: absolute;
    top: 12vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.SavedBlogpage{
    width: 100vw;
    height: auto;
}
.BlogSavedCard{
    width: 45vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 4vh;
    grid-template-columns: 1fr 1fr;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 28vw;
    height: 37vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 28vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    position: absolute;
    /*background: green;*/
    height: 17vh;
    width: 26vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linksaved{
    /*background: gray;*/
    /*position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;*/
    /*background: black;*/
    margin-top: 1vh;
    height: 3vh;
    width: 50vw;
}
.options-menu3{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}

/*For large screens:*/
@media(min-width: 1024px){
.BlogSavedCard{
    width: 45vw;
    height: auto;
    /*padding: 6rem 1rem;*/
    display: grid;
    grid-gap: 4vh;
    grid-template-columns: 1fr 1fr;
    margin-top: 2vh;
    /*background-color: green;*/
}
.CardTrendingStory{
    position: absolute;
    right: 0px;
    top: 0px;
    margin-top: 5vh;
    margin-right: 5vw;
}
.SavedBlog {
    width: 100vw;
    height: 50vh;
    /*padding: 6rem 1rem;*/
    background-color: white;
}
.BlogSavedTitleCard{
    background: gray;
    width: 28vw;
    height: 30vh;
    margin-left: 2vw;
    padding: 1rem;
    /*margin-top: 5vh;*/
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}
.Blogsavedimg{
    height: 10vh;
    width: 28vw;
    object-fit: cover;
}
.BlogTextSavedPart{
    position: absolute;
    /*background: green;*/
    height: 17vh;
    width: 26vw;
    text-align: justify;
}
.BlogSavedTitle{
    font-size: 1.5rem;
    font-weight: bold;
}
.linksaved{
    /*background: gray;*/
    position: absolute;
    vertical-align: bottom;
    right: 0px;
    bottom: 2vh;
    /*background: black;*/
    height: 3vh;
    width: 10vw;
}
.options-menu3{
    position: absolute;
    top: 42vh;
    display: flex;
    justify-content: space-around;
    background-color: transparent;
    width: auto;
    height: 5vh;
    left: 2vw;
    border-radius: 20px;
    transition: all 0.1s linear;
    vertical-align: middle;

}
.options-menu3 h3{
    position: relative;
    left: 3.5vw;
    font-size: 0.9rem;
    height: 90%;
    line-height: 4vh;
    width: 15vw;
    
    border-radius: 20px;
    margin: 5px;
    padding-left: 2vw;
    padding-right: 2vw;
    
    vertical-align: middle;
    text-align: center;
    background-color: #0d66d0;


}
.options-menu3 h3:hover{
   border: 1px solid whitesmoke;
   background-color: #0d66f2;

}
/*.card:hover {
    background-color: #141414;
}*/
}
