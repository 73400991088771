

/*For mobile phones:*/
@media(min-width: 240px) and (max-width: 480px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 10vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url('./blogtop.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 20vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url('./blogtop.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url('./blogtop.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}

/*For Large screens:*/
@media(min-width: 1024px){
.blog-top {
    background: rgba(0,0,0,.3);
    height: 50vh;
    width: 100%;
    position: relative;
}

.blog-top:before {
    content:'';
    background: url('./blogtop.jpg') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.blog-top .heading {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-top h1 {
    font-size: 2.4rem;
}

.blog-top p {
    font-size: 1.4rem;
}
}
