/*For Mobile Phones:*/
@media(min-width: 240px) and (max-width: 480px){
.TrendingStoriesCardSide{
	position: absolute;
	top: 40vh;
	left: 5vw;
	height: 50vh;
	width: 90vw;
	background-color: white;
	display: grid;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 5px 8px 5px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	position: absolute;
	top: 10vh;
	left: 0px;
	height: 35vh;
	width: 88vw;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	/*justify-content: space-around;*/
	/*background-color: green;*/
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 3vw;
	display: grid;
	grid-gap: 1vh;
	/*justify-content: space-around;*/
	height: 12vh;
	width: 35vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 35vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 35vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}


/*For Tablets and iPads:*/
@media(min-width: 481px) and (max-width: 868px){
.TrendingStoriesCardSide{
	position: absolute;
	background-color: blue;
	top: 55vh;
	left: 5vw;
	height: 50vh;
	width: 90vw;
	background-color: white;
	display: grid;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 5px 8px 5px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	position: absolute;
	top: 10vh;
	left: 0px;
	height: 35vh;
	width: 88vw;
	overflow-x: scroll;
	overflow-y: hidden;
	display: flex;
	/*justify-content: space-around;*/
	/*background-color: green;*/
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 3vw;
	display: grid;
	grid-gap: 1vh;
	/*justify-content: space-around;*/
	height: 12vh;
	width: 35vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 35vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 35vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}


/*For small screen laptops:*/
@media(min-width: 869px) and (max-width: 1023px){
.TrendingStoriesCardSide{
	height: 90vh;
	width: 30vw;
	background-color: white;
	display: grid;
	grid-gap: 1vh;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 0px 8px 0px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	overflow-y: scroll;
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 1vw;
	display: flex;
	justify-content: space-between;
	height: 12vh;
	width: 28vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 8vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 19vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}


/*For large screens:*/
@media(min-width: 1024px){
.TrendingStoriesCardSide{
	height: 90vh;
	width: 30vw;
	background-color: white;
	display: grid;
	grid-gap: 1vh;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	box-shadow: 8px 0px 8px 0px #262525;
}
.TrendingStoriesMainText{
	/*position: absolute;*/
	/*background-color: red;*/
	margin-top: 3vh;
	height: 5vh;
	color: #0d66d0;
	font-weight: bold;
	font-family: Montserrat;
	width: 100%;
	text-align: center;
	border-top-right-radius: 25px;
	border-top-left-radius: 25px;
	font-size: 1.7rem;
	/*top: 2vh;*/
}
.AllBlogStoryCard{
	overflow-y: scroll;
}
.BlogstoryCard{
	/*position: absolute;*/
	/*background-color: green;*/
	margin-left: 1vw;
	display: flex;
	justify-content: space-between;
	height: 12vh;
	width: 28vw;
}
.Blogstoryimg{
	height: 10vh;
	margin-top: 1vh;
	width: 8vw;
	object-fit: cover;
	box-shadow: 3px 3px 3px 3px #808080;
}
.Blogstorytext{
	font-size: 0.85rem;
	height: 10vh;
	width: 19vw;
	text-align: justify;
	margin-top: 0.5vh;
}
}

